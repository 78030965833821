// Static data that is used across platforms

export const phoneCodes = [
	{ name: '🇧🇪 Belgium (+32)', value: '+32' },
	{ name: '🇧🇬 Bulgaria (+359)', value: '+359' },
	{ name: '🇨🇦 Canada & United States (+1)', value: '+1' },
	{ name: '🇭🇷 Croatia (+385)', value: '+385' },
	{ name: '🇨🇾 Cyprus (+357)', value: '+357' },
	{ name: '🇨🇿 Czech Republic (+420)', value: '+420' },
	{ name: '🇩🇰 Denmark (+45)', value: '+45' },
	{ name: '🇪🇪 Estonia (+372)', value: '+372' },
	{ name: '🇫🇴 Faroe Islands (+298)', value: '+298' },
	{ name: '🇫🇮 Finland (+358)', value: '+358' },
	{ name: '🇫🇷 France (+33)', value: '+33' },
	{ name: '🇬🇪 Georgia (+995)', value: '+995' },
	{ name: '🇩🇪 Germany (+49)', value: '+49' },
	{ name: '🇬🇮 Gibraltar (+350)', value: '+350' },
	{ name: '🇬🇷 Greece (+30)', value: '+30' },
	{ name: '🇭🇺 Hungary (+36)', value: '+36' },
	{ name: '🇮🇸 Iceland (+354)', value: '+354' },
	{ name: '🇮🇳 India (+91)', value: '+91' },
	{ name: '🇮🇪 Ireland (+353)', value: '+353' },
	{ name: '🇮🇱 Israel (+972)', value: '+972' },
	{ name: '🇮🇹 Italy (+39)', value: '+39' },
	{ name: '🇱🇻 Latvia (+371)', value: '+371' },
	{ name: '🇱🇮 Liechtenstein (+423)', value: '+423' },
	{ name: '🇱🇹 Lithuania (+370)', value: '+370' },
	{ name: '🇱🇺 Luxembourg (+352)', value: '+352' },
	{ name: '🇲🇰 Macedonia (+389)', value: '+389' },
	{ name: '🇲🇹 Malta (+356)', value: '+356' },
	{ name: '🇲🇩 Moldova (+373)', value: '+373' },
	{ name: '🇲🇨 Monaco (+377)', value: '+377' },
	{ name: '🇲🇪 Montenegro (+382)', value: '+382' },
	{ name: '🇳🇱 Netherlands (+31)', value: '+31' },
	{ name: '🇳🇴 Norway (+47)', value: '+47' },
	{ name: '🇵🇱 Poland (+48)', value: '+48' },
	{ name: '🇵🇹 Portugal (+351)', value: '+351' },
	{ name: '🇷🇴 Romania (+40)', value: '+40' },
	{ name: '🇷🇺 Russia (+7)', value: '+7' },
	{ name: '🇷🇸 Serbia (+381)', value: '+381' },
	{ name: '🇸🇰 Slovakia (+421)', value: '+421' },
	{ name: '🇸🇮 Slovenia (+386)', value: '+386' },
	{ name: '🇪🇸 Spain (+34)', value: '+34' },
	{ name: '🇸🇪 Sweden (+46)', value: '+46' },
	{ name: '🇨🇭 Switzerland (+41)', value: '+41' },
	{ name: '🇹🇷 Turkey (+90)', value: '+90' },
	{ name: '🇺🇦 Ukraine (+380)', value: '+380' },
	{ name: '🇬🇧 United Kingdom (+44)', value: '+44' },
	{ name: '🇨🇱 Chile (+56)', value: '+56' },
	{ name: '🇵🇦 Panama (+507)', value: '+507' }
];
export const countriesMap = [
	{ name: 'Belgium', value: 'BE' },
	{ name: 'France', value: 'FR' },
	{ name: 'Germany', value: 'DE' },
	{ name: 'Italy', value: 'IT' },
	{ name: 'Poland', value: 'PL' },
	{ name: 'Spain', value: 'ES' },
	{ name: 'United Kingdom', value: 'GB' },
	{ name: 'United States', value: 'US' },
	{ name: '---', value: '---' },
	{ name: 'Afghanistan', value: 'AF' },
	{ name: 'Åland Islands', value: 'AX' },
	{ name: 'Albania', value: 'AL' },
	{ name: 'Algeria', value: 'DZ' },
	{ name: 'American Samoa', value: 'AS' },
	{ name: 'Andorra', value: 'AD' },
	{ name: 'Angola', value: 'AO' },
	{ name: 'Anguilla', value: 'AI' },
	{ name: 'Antarctica', value: 'AQ' },
	{ name: 'Antigua and Barbuda', value: 'AG' },
	{ name: 'Argentina', value: 'AR' },
	{ name: 'Armenia', value: 'AM' },
	{ name: 'Aruba', value: 'AW' },
	{ name: 'Australia', value: 'AU' },
	{ name: 'Austria', value: 'AT' },
	{ name: 'Azerbaijan', value: 'AZ' },
	{ name: 'Bahamas', value: 'BS' },
	{ name: 'Bahrain', value: 'BH' },
	{ name: 'Bangladesh', value: 'BD' },
	{ name: 'Barbados', value: 'BB' },
	{ name: 'Belarus', value: 'BY' },
	{ name: 'Belgium', value: 'BE' },
	{ name: 'Belize', value: 'BZ' },
	{ name: 'Benin', value: 'BJ' },
	{ name: 'Bermuda', value: 'BM' },
	{ name: 'Bhutan', value: 'BT' },
	{ name: 'Bolivia', value: 'BO' },
	{ name: 'Bosnia and Herzegovina', value: 'BA' },
	{ name: 'Botswana', value: 'BW' },
	{ name: 'Bouvet Island', value: 'BV' },
	{ name: 'Brazil', value: 'BR' },
	{ name: 'British Indian Ocean Territory', value: 'IO' },
	{ name: 'Brunei Darussalam', value: 'BN' },
	{ name: 'Bulgaria', value: 'BG' },
	{ name: 'Burkina Faso', value: 'BF' },
	{ name: 'Burundi', value: 'BI' },
	{ name: 'Cambodia', value: 'KH' },
	{ name: 'Cameroon', value: 'CM' },
	{ name: 'Canada', value: 'CA' },
	{ name: 'Cape Verde', value: 'CV' },
	{ name: 'Cayman Islands', value: 'KY' },
	{ name: 'Central African Republic', value: 'CF' },
	{ name: 'Chad', value: 'TD' },
	{ name: 'Chile', value: 'CL' },
	{ name: 'China', value: 'CN' },
	{ name: 'Christmas Island', value: 'CX' },
	{ name: 'Cocos (Keeling) Islands', value: 'CC' },
	{ name: 'Colombia', value: 'CO' },
	{ name: 'Comoros', value: 'KM' },
	{ name: 'Congo', value: 'CG' },
	{ name: 'Congo, The Democratic Republic of the', value: 'CD' },
	{ name: 'Cook Islands', value: 'CK' },
	{ name: 'Costa Rica', value: 'CR' },
	{ name: "Cote D'Ivoire", value: 'CI' },
	{ name: 'Croatia', value: 'HR' },
	{ name: 'Cuba', value: 'CU' },
	{ name: 'Cyprus', value: 'CY' },
	{ name: 'Czech Republic', value: 'CZ' },
	{ name: 'Denmark', value: 'DK' },
	{ name: 'Djibouti', value: 'DJ' },
	{ name: 'Dominica', value: 'DM' },
	{ name: 'Dominican Republic', value: 'DO' },
	{ name: 'Ecuador', value: 'EC' },
	{ name: 'Egypt', value: 'EG' },
	{ name: 'El Salvador', value: 'SV' },
	{ name: 'Equatorial Guinea', value: 'GQ' },
	{ name: 'Eritrea', value: 'ER' },
	{ name: 'Estonia', value: 'EE' },
	{ name: 'Ethiopia', value: 'ET' },
	{ name: 'Falkland Islands (Malvinas)', value: 'FK' },
	{ name: 'Faroe Islands', value: 'FO' },
	{ name: 'Fiji', value: 'FJ' },
	{ name: 'Finland', value: 'FI' },
	{ name: 'France', value: 'FR' },
	{ name: 'French Guiana', value: 'GF' },
	{ name: 'French Polynesia', value: 'PF' },
	{ name: 'French Southern Territories', value: 'TF' },
	{ name: 'Gabon', value: 'GA' },
	{ name: 'Gambia', value: 'GM' },
	{ name: 'Georgia', value: 'GE' },
	{ name: 'Germany', value: 'DE' },
	{ name: 'Ghana', value: 'GH' },
	{ name: 'Gibraltar', value: 'GI' },
	{ name: 'Greece', value: 'GR' },
	{ name: 'Greenland', value: 'GL' },
	{ name: 'Grenada', value: 'GD' },
	{ name: 'Guadeloupe', value: 'GP' },
	{ name: 'Guam', value: 'GU' },
	{ name: 'Guatemala', value: 'GT' },
	{ name: 'Guernsey', value: 'GG' },
	{ name: 'Guinea', value: 'GN' },
	{ name: 'Guinea-Bissau', value: 'GW' },
	{ name: 'Guyana', value: 'GY' },
	{ name: 'Haiti', value: 'HT' },
	{ name: 'Heard Island and Mcdonald Islands', value: 'HM' },
	{ name: 'Holy See (Vatican City State)', value: 'VA' },
	{ name: 'Honduras', value: 'HN' },
	{ name: 'Hong Kong', value: 'HK' },
	{ name: 'Hungary', value: 'HU' },
	{ name: 'Iceland', value: 'IS' },
	{ name: 'India', value: 'IN' },
	{ name: 'Indonesia', value: 'ID' },
	{ name: 'Iran, Islamic Republic Of', value: 'IR' },
	{ name: 'Iraq', value: 'IQ' },
	{ name: 'Ireland', value: 'IE' },
	{ name: 'Isle of Man', value: 'IM' },
	{ name: 'Israel', value: 'IL' },
	{ name: 'Italy', value: 'IT' },
	{ name: 'Jamaica', value: 'JM' },
	{ name: 'Japan', value: 'JP' },
	{ name: 'Jersey', value: 'JE' },
	{ name: 'Jordan', value: 'JO' },
	{ name: 'Kazakhstan', value: 'KZ' },
	{ name: 'Kenya', value: 'KE' },
	{ name: 'Kiribati', value: 'KI' },
	{ name: "Korea, Democratic People'S Republic of", value: 'KP' },
	{ name: 'Korea, Republic of', value: 'KR' },
	{ name: 'Kuwait', value: 'KW' },
	{ name: 'Kyrgyzstan', value: 'KG' },
	{ name: "Lao People'S Democratic Republic", value: 'LA' },
	{ name: 'Latvia', value: 'LV' },
	{ name: 'Lebanon', value: 'LB' },
	{ name: 'Lesotho', value: 'LS' },
	{ name: 'Liberia', value: 'LR' },
	{ name: 'Libyan Arab Jamahiriya', value: 'LY' },
	{ name: 'Liechtenstein', value: 'LI' },
	{ name: 'Lithuania', value: 'LT' },
	{ name: 'Luxembourg', value: 'LU' },
	{ name: 'Macao', value: 'MO' },
	{ name: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
	{ name: 'Madagascar', value: 'MG' },
	{ name: 'Malawi', value: 'MW' },
	{ name: 'Malaysia', value: 'MY' },
	{ name: 'Maldives', value: 'MV' },
	{ name: 'Mali', value: 'ML' },
	{ name: 'Malta', value: 'MT' },
	{ name: 'Marshall Islands', value: 'MH' },
	{ name: 'Martinique', value: 'MQ' },
	{ name: 'Mauritania', value: 'MR' },
	{ name: 'Mauritius', value: 'MU' },
	{ name: 'Mayotte', value: 'YT' },
	{ name: 'Mexico', value: 'MX' },
	{ name: 'Micronesia, Federated States of', value: 'FM' },
	{ name: 'Moldova, Republic of', value: 'MD' },
	{ name: 'Monaco', value: 'MC' },
	{ name: 'Mongolia', value: 'MN' },
	{ name: 'Montserrat', value: 'MS' },
	{ name: 'Morocco', value: 'MA' },
	{ name: 'Mozambique', value: 'MZ' },
	{ name: 'Myanmar', value: 'MM' },
	{ name: 'Namibia', value: 'NA' },
	{ name: 'Nauru', value: 'NR' },
	{ name: 'Nepal', value: 'NP' },
	{ name: 'Netherlands', value: 'NL' },
	{ name: 'Netherlands Antilles', value: 'AN' },
	{ name: 'New Caledonia', value: 'NC' },
	{ name: 'New Zealand', value: 'NZ' },
	{ name: 'Nicaragua', value: 'NI' },
	{ name: 'Niger', value: 'NE' },
	{ name: 'Nigeria', value: 'NG' },
	{ name: 'Niue', value: 'NU' },
	{ name: 'Norfolk Island', value: 'NF' },
	{ name: 'Northern Mariana Islands', value: 'MP' },
	{ name: 'Norway', value: 'NO' },
	{ name: 'Oman', value: 'OM' },
	{ name: 'Pakistan', value: 'PK' },
	{ name: 'Palau', value: 'PW' },
	{ name: 'Palestinian Territory, Occupied', value: 'PS' },
	{ name: 'Panama', value: 'PA' },
	{ name: 'Papua New Guinea', value: 'PG' },
	{ name: 'Paraguay', value: 'PY' },
	{ name: 'Peru', value: 'PE' },
	{ name: 'Philippines', value: 'PH' },
	{ name: 'Pitcairn', value: 'PN' },
	{ name: 'Poland', value: 'PL' },
	{ name: 'Portugal', value: 'PT' },
	{ name: 'Puerto Rico', value: 'PR' },
	{ name: 'Qatar', value: 'QA' },
	{ name: 'Reunion', value: 'RE' },
	{ name: 'Romania', value: 'RO' },
	{ name: 'Russian Federation', value: 'RU' },
	{ name: 'RWANDA', value: 'RW' },
	{ name: 'Saint Helena', value: 'SH' },
	{ name: 'Saint Kitts and Nevis', value: 'KN' },
	{ name: 'Saint Lucia', value: 'LC' },
	{ name: 'Saint Pierre and Miquelon', value: 'PM' },
	{ name: 'Saint Vincent and the Grenadines', value: 'VC' },
	{ name: 'Samoa', value: 'WS' },
	{ name: 'San Marino', value: 'SM' },
	{ name: 'Sao Tome and Principe', value: 'ST' },
	{ name: 'Saudi Arabia', value: 'SA' },
	{ name: 'Senegal', value: 'SN' },
	{ name: 'Serbia and Montenegro', value: 'CS' },
	{ name: 'Seychelles', value: 'SC' },
	{ name: 'Sierra Leone', value: 'SL' },
	{ name: 'Singapore', value: 'SG' },
	{ name: 'Slovakia', value: 'SK' },
	{ name: 'Slovenia', value: 'SI' },
	{ name: 'Solomon Islands', value: 'SB' },
	{ name: 'Somalia', value: 'SO' },
	{ name: 'South Africa', value: 'ZA' },
	{ name: 'South Georgia and the South Sandwich Islands', value: 'GS' },
	{ name: 'Spain', value: 'ES' },
	{ name: 'Sri Lanka', value: 'LK' },
	{ name: 'Sudan', value: 'SD' },
	{ name: 'Suriname', value: 'SR' },
	{ name: 'Svalbard and Jan Mayen', value: 'SJ' },
	{ name: 'Swaziland', value: 'SZ' },
	{ name: 'Sweden', value: 'SE' },
	{ name: 'Switzerland', value: 'CH' },
	{ name: 'Syrian Arab Republic', value: 'SY' },
	{ name: 'Taiwan, Province of China', value: 'TW' },
	{ name: 'Tajikistan', value: 'TJ' },
	{ name: 'Tanzania, United Republic of', value: 'TZ' },
	{ name: 'Thailand', value: 'TH' },
	{ name: 'Timor-Leste', value: 'TL' },
	{ name: 'Togo', value: 'TG' },
	{ name: 'Tokelau', value: 'TK' },
	{ name: 'Tonga', value: 'TO' },
	{ name: 'Trinidad and Tobago', value: 'TT' },
	{ name: 'Tunisia', value: 'TN' },
	{ name: 'Turkey', value: 'TR' },
	{ name: 'Turkmenistan', value: 'TM' },
	{ name: 'Turks and Caicos Islands', value: 'TC' },
	{ name: 'Tuvalu', value: 'TV' },
	{ name: 'Uganda', value: 'UG' },
	{ name: 'Ukraine', value: 'UA' },
	{ name: 'United Arab Emirates', value: 'AE' },
	{ name: 'United Kingdom', value: 'GB' },
	{ name: 'United States', value: 'US' },
	{ name: 'United States Minor Outlying Islands', value: 'UM' },
	{ name: 'Uruguay', value: 'UY' },
	{ name: 'Uzbekistan', value: 'UZ' },
	{ name: 'Vanuatu', value: 'VU' },
	{ name: 'Venezuela', value: 'VE' },
	{ name: 'Viet Nam', value: 'VN' },
	{ name: 'Virgin Islands, British', value: 'VG' },
	{ name: 'Virgin Islands, U.S.', value: 'VI' },
	{ name: 'Wallis and Futuna', value: 'WF' },
	{ name: 'Western Sahara', value: 'EH' },
	{ name: 'Yemen', value: 'YE' },
	{ name: 'Zambia', value: 'ZM' },
	{ name: 'Zimbabwe', value: 'ZW' }
];
