import * as Core from '@Core/index.js';
import { useBusinessStore } from './business.js';

/**
 * @exports Client_Store_User
 * @namespace Client_Store_User
 */

export const useUserStore = Core.Pinia.defineStore({
	id: 'user',
	state: () => ({
		data: {
			user: {
				firstName: '',
				lastName: '',
				occupation: '',
				email: '',
				department: '',
				countryPhonePrefix: '',
				phoneNumber: '',
				language: 'EN',
				userId: ''
			},
			groups: [],
			loggedIn: false
		}
	}),

	actions: {
		/**
		 * Retrieve User data from the API (user has to be logged in).
		 * It will set all store data.
		 * @async
		 * @memberof Client_Store_User
		 * @returns {Promise<object>} response json from api
		 */
		async fetchData() {
			const response = await Core.Api.get('/user');
			this.data.user = response.body;

			// set groups from cognito
			const loginData = await Core.Aws.Auth.loggedIn();
			this.data.loggedIn = true;
			this.data.groups =
				loginData.signInUserSession.accessToken.payload['cognito:groups'] || [];
		},

		/**
		 * Save data back to the API. Response will confirm if everything is fine.
		 * If errors, details will be provided in the body response.
		 * @async
		 * @memberof Client_Store_User
		 * @param {...object} new complete state.data values to be send to the backend (email will be excluded)
		 * @returns {Promise<object>} response object {code, body}
		 */
		async saveData({ email, userId, ...data }) {
			// email has to be excluded as backend doesn't accept it
			const submitResponse = await Core.Api.post('/user', data);
			this.data.user = submitResponse.body;
			return submitResponse;
		},

		/**
		 * Check groups required by something against available access for currently logged in user.
		 * @memberof Client_Store_User
		 * @param {string} groupsRequired list of groups, comma seperated
		 * @returns {boolean} response if groups passsed or not
		 */
		checkGroups(groupsRequired) {
			const groups = groupsRequired.split(',');
			for (const item in groups) {
				if (this.data.groups.includes(groups[item])) {
					return true;
				}
			}

			return false;
		}
	},
	getters: {
		/**
		 * Get current user data from the store
		 * @memberof Client_Store_User
		 * @param {object} state automatically passed in
		 * @returns {object} user data object
		 */
		getUser(state) {
			return state.data.user;
		},

		/**
		 * Status if user is loggedin right now (cognito)
		 * @memberof Client_Store_User
		 * @param {object} state automatically passed in
		 * @returns {boolean} status for loggedin state
		 */
		isLoggedIn(state) {
			return state.data.loggedIn;
		},

		/**
		 * Check if user is Auditor (omnevue team)
		 * @memberof Client_Store_User
		 * @returns {boolean} status of auditor group
		 */
		isAuditor() {
			return this.checkGroups('auditors');
		},

		/**
		 * Check if user is Omnevue staff
		 * @memberof Client_Store_User
		 * @returns {boolean} status of Omnevue association
		 */
		isOmnevue() {
			return (
				this.checkGroups('administrators') ||
				this.checkGroups('developers') ||
				this.checkGroups('auditors')
			);
		},

		/**
		 * Check if user is an owner of currently selected business
		 * @memberof Client_Store_User
		 * @returns {boolean} status for ownership
		 */
		isOwner() {
			const businessStore = useBusinessStore();
			return (
				businessStore.getCurrentBusiness?.role === 'OWNER' || this.checkGroups('auditors')
			);
		},

		/**
		 * Check if user is a collaborator on currently selected business
		 * @memberof Client_Store_User
		 * @returns {boolean} status of collaborator
		 */
		isCollaborator() {
			const businessStore = useBusinessStore();
			return businessStore.getCurrentBusiness?.role === 'ADMIN';
		},

		/**
		 * Check if user should see reports
		 * @memberof Client_Store_User
		 * @returns {boolean} status of reports visibility
		 */
		shouldSeeReports() {
			const businessStore = useBusinessStore();
			const business = businessStore.getCurrentBusiness;
			return (
				this.checkGroups('auditors') ||
				this.checkGroups('external-auditors') ||
				this.isOwner ||
				business.permissions.includes('VIEW_REPORTS')
			);
		}
	}
});
