<template>
	<div>
		<div
			class="BpForms BpAddress"
			:data-testid="id + '-address'">
			<blueprint-input
				:id="id + '-address'"
				ref="inputField"
				v-model:value="computedValue"
				class="BpAddress--inputField"
				:class="suggestionsComputed.length > 0 || loading ? 'is-open' : ''"
				:label="label"
				:placeholder="placeholder"
				:disabled="disabled"
				:maxlength="maxLength"
				:msg="msg"
				:debounce-value-ms="750"
				:required="required"
				icon="iconFont-pin"
				:tooltip="tooltip"
				:on-focus="loadScript"
				@is-typing-started="startedTyping"></blueprint-input>

			<transition name="fade">
				<div
					v-if="loading || suggestionsComputed.length > 0"
					class="BpForms--addressSuggestionsContainer BpAddress--addressOptions Space-topNegative"
					:class="validate() ? 'is-success' : 'is-notification'">
					<div class="BpForms--suggestions">
						<template v-if="!loading && suggestionsComputed.length > 0">
							<div
								v-for="(option, i) of suggestionsComputed"
								:key="i"
								class="BpForms--suggestionItem"
								@click="computedValue = option">
								{{ option }}
							</div>
						</template>
						<blueprint-loader
							v-else
							class="Space-top Space-bottom" />
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'BlueprintAddress',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Input
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string | number| null} [value] - bind value
			 * @namespace Core_Blueprint_Input
			 * @property {string | number| null} [value] - bind value
			 */
			value: {
				type: [String, Number, null],
				required: false,
				default: null
			},

			/**
			 * property {string} [label] - label or title
			 * @namespace Core_Blueprint_Input
			 * @property {string} [label] - label or title
			 */
			label: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [placeholder] - input placeholder text
			 * @namespace Core_Blueprint_Input
			 * @property {string} [placeholder] - input placeholder text
			 */
			placeholder: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {number} [maxLength] - max number of characters
			 * @namespace Core_Blueprint_Input
			 * @property {number} [maxLength] - max number of characters
			 */
			maxLength: {
				type: Number,
				required: false,
				default: null
			},

			/**
			 * property {boolean} [disabled] - should this field be "disabled"
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [disabled] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},

			/**
			 * property {boolean} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Input
			 * @property {boolean} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_Input
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Input
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: null
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			/* globals google */

			const language = useLanguageStore();
			const inputField = Core.Vue.ref();
			const suggestions = Core.Vue.ref([]);
			const loading = Core.Vue.ref(false);

			const computedValue = Core.Vue.computed({
				get() {
					return props.value;
				},
				set(newValue) {
					context.emit('update:value', newValue);
					// ps, no need for debounce as it's handled by the blueprint-input component
				}
			});

			// Variable to track the timer for 'fake' loading for addresses

			const suggestionsComputed = Core.Vue.computed({
				get() {
					return suggestions.value;
				},
				set(newValue) {
					const filtered = [];
					for (const item in newValue) {
						filtered.push(newValue[item].description);
					}
					suggestions.value = filtered;
				}
			});

			let service;
			// deal with google maps places
			/**
			 *
			 */
			async function loadScript() {
				if (document.getElementById('googlePlacesScript')) {
					startService();
				} else {
					await Core.Utils.injectScript(
						`https://maps.googleapis.com/maps/api/js?key=${Core.AppConfig.config.MAPS_KEY}&libraries=places`,
						'googlePlacesScript',
						false
					);
					startService();
				}
			}

			/**
			 * Initialise autocomplete with google
			 */
			function startService() {
				if (!service) {
					service = new google.maps.places.AutocompleteService();
				}
			}

			Core.Vue.watch(computedValue, (newValue) => {
				// make sure it's a string first
				if (typeof newValue === 'string') {
					newValue.replace('"', '\\"').replace(/^\s+|\s+$/g, '');
				}

				// check if it already exists in suggestions, so we save ourselv api trip
				let valueInSuggestions = false;
				for (const item in suggestionsComputed.value) {
					if (suggestionsComputed.value[item] === newValue) {
						valueInSuggestions = true;
					}
				}

				if (newValue !== '' && !valueInSuggestions && service) {
					service.getPlacePredictions(
						{
							input: newValue
						},
						updateSuggestions
					);
				} else {
					updateSuggestions('');
				}
			});

			/**
			 * this will populated the state with correct data
			 * @param {Array} vars response from Places Services
			 */
			function updateSuggestions(vars) {
				suggestionsComputed.value = vars;
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				inputField.value.reset();
			}

			// -------------- VALIDATION  --------------
			/**
			 * validate() validation function to check the input on string if it's alligned with the requirements.
			 * Returns Boolean
			 * @namespace Core_Blueprint_Address
			 * @returns {object} validation result
			 */
			function validate() {
				return inputField.value.validate();
			}

			/**
			 * Track when user starts typing
			 * @param {boolean} isTyping - started typing or not
			 */
			function startedTyping(isTyping) {
				loading.value = isTyping;
			}

			return {
				language,
				validate,
				reset,
				inputField,
				loadScript,
				computedValue,
				suggestionsComputed,
				loading,
				startedTyping
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpAddress') {
		position: relative;

		@include element('addressOptions') {
			@include state('success') {
				border: 2px solid var(--color-stateSuccess);
				border-top: none;
			}
		}

		@include element('inputField') {
			@include state('open') {
				.BpForms--input {
					border-bottom: 0;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
		@include element('list') {
		}
	}
</style>
