/**
 @module Core/Api
 */
import * as Aws from '@Core/modules/Aws/index.js';
import * as Event from '@Core/modules/Event/index.js';
import * as AppConfig from '@Core/modules/AppConfig/index.js';

/* ------------------- PRIVATE METHODS -------------------------- */
/**
 * @private
 * @param {string} type error type
 * @param {object} response api call response body
 */
function handleError(type, response) {
	Event.trigger('ERROR', {
		type: `API_${type}`,
		log: response
	});

	throw { response }; // throwing so we make sure any triggers fail after api failure, as expected
}

/**
 * Adds access token to auth header
 * @private
 * @param {object} options - options object that will be passed to fetch
 */
async function addAccessToken(options) {
	// always add auth token from AWS if available
	const accessToken = Aws.Auth.token || (await Aws.Auth.getAccessToken());
	if (accessToken) {
		options.headers ? false : (options.headers = {});
		options.headers.Authorization = `Bearer ${accessToken}`;
	}
}

/**
 * Creates HTTP method handler
 * @private
 * @param {string} method - method for the handler
 * @returns {Function} - new rest handler
 */
function createHttpMethodHandler(method) {
	/**
	 * @async
	 * @param {string} url - the url to fetch
	 * @param {object} data - data to send back to the server, key-value pairs
	 * @param {object} [options={}] - fetch() options object that can be passed
	 * @returns {Promise} response from the server in {status, body} format
	 */
	return async function (url = '', data = {}, options = {}) {
		options.method = method;
		if (method !== 'GET') {
			options.body = JSON.stringify(data);
		}

		addAccessToken(options);

		const response = await fetch(AppConfig.config.API + url, options).catch(() => {
			// Handle network error
			return {};
		});
		const finalResponse = {};

		// parse readableStream
		if (response.status >= 200 && response.status <= 299) {
			if (response.headers.get('Content-Length') !== '0') {
				finalResponse.body = await response.json();
			}
		} else if (response.status === 409) {
			finalResponse.body = await response.json();
		} else if (response.status === 404) {
			// Throw error and handle 404 outside of this if needed
			throw new Error('404 - Not found error');
		} else {
			// Handle network error OR other unexepcted crap
			try {
				finalResponse.body = await response.json();
			} catch (e) {
				finalResponse.body = {};
				throw new Error('XXX - Unexpected error');
			}
			handleError(method, finalResponse.body);
		}

		finalResponse.status = response.status;
		window.app.log(`API`, `${method} ${url}`, finalResponse);

		return finalResponse;
	};
}

/* ------------------- PUBLIC METHODS -------------------------- */
/**
 * make an API call using GET method
 * @param {string} url - the uri to fetch
 * @param {object} data - data to send back to the server, key-value pairs
 * @param {object} [options={}] - fetch() options object that can be passed
 * @returns {Promise} response from the server in {status, body} format
 */
export const get = createHttpMethodHandler('GET');

/**
 * make an API call using POST method
 * @param {string} url - the uri to fetch
 * @param {object} data - data to send back to the server, key-value pairs
 * @param {object} [options={}] - fetch() options object that can be passed
 * @returns {Promise} response from the server in {status, body} format
 */
export const post = createHttpMethodHandler('POST');

/**
 * make an API call using PUT method
 * @param {string} url - the uri to fetch
 * @param {object} data - data to send back to the server, key-value pairs
 * @param {object} [options={}] - fetch() options object that can be passed
 * @returns {Promise} response from the server in {status, body} format
 */
export const put = createHttpMethodHandler('PUT');

/**
 * make an API call using DELETE method
 * @param {string} url - the uri to fetch
 * @param {object} data - data to send back to the server, key-value pairs
 * @param {object} [options={}] - fetch() options object that can be passed
 * @returns {Promise} response from the server in {status, body} format
 */
export const del = createHttpMethodHandler('DELETE'); // cannot use 'delete" as it's a keyword

/**
 * Make an external API call
 * @param {string} fullUrl full url path with http etc.
 * @param {object} [options={}] - fetch() options object that can be passed
 * @returns {Promise} response from the server in {status, body} format
 */
export async function getExternal(fullUrl, options = {}) {
	const response = await fetch(fullUrl, options);
	const finalResponse = {};

	// parse readableStream
	if (response.status >= 200 && response.status <= 299) {
		finalResponse.body = await response.json();
	} else {
		handleError('get', response);
	}

	finalResponse.status = response.status;
	window.app.log('API GET', fullUrl, response.status);

	return finalResponse;
}

/**
 * Get public bucket resource, handle multiple response content types and parse them correctly - currently application/json - binary/octet-stream
 * @param {string} url - the url to fetch
 * @returns {Promise} response from the server in {status, body} format
 */
export async function getPublicDataResource(url) {
	try {
		// Environment URL for public bucket
		const envBucketUrl = AppConfig.config.PUBLIC_BUCKET;

		// Fetch OpenAPI data from S3 public bucket
		const response = await fetch(`${envBucketUrl}${url}`);
		const contentType = response.headers.get('Content-type');

		// Parse response correctly according to content type
		if (contentType === 'application/json') {
			const parsedResponse = await response.json();
			return parsedResponse;
		} else if (contentType === 'binary/octet-stream') {
			const blob = await response.blob();
			const data = await blob.text();
			return data;
		}
	} catch (e) {
		Event.trigger('ERROR', {
			type: 'API_GET'
		});
	}
}
