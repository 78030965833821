<template>
	<core-navigation :config="navConfig" />
</template>

<script>
	import * as Core from '@Core/index.js';
	import * as Store from '@Client/store/index.js';

	export default {
		//  ---------- COMPONENTS ----------
		components: {
			'core-navigation': Core.cNavigation
		},

		//  ---------- SETUP ----------
		setup() {
			// navigation config
			const navConfig = Core.Vue.computed(() => {
				// Check if there are any product purchases
				// @TODO: This needs to be moved to store somewhere (probably product store?)
				const hasPurchases =
					Store.product.hasVueesgProducts || Store.product.hasVueCarbonProducts;

				// @TODO this store method probably should return true/false only and to get actuall items it should start with "get"
				const hasCompletedEsgProducts = !!(
					Object.keys(Store.product.getCompletedProducts).length > 0
				);
				const hasCompletedCarbonProducts = !!(
					Object.keys(Store.product.getCompletedProducts).length > 0
				);

				const config = [
					{
						name: Store.language.string.cClientNav.dashboard,
						link: '/dashboard',
						icon: 'iconFont-dashboard'
					},
					{
						name: Store.language.string.cClientNav.productsBuyAndUpgrade,
						link: '/purchase',
						icon: 'iconFont-products',
						hidden: Store.business.isSubsidiary
					},

					// Vue ESG
					{
						name: Store.language.string.cClientNav.productEsg,
						link: '/vueesg',
						icon: 'iconFont-productVueEsg',
						hidden: !Store.product.hasVueesgProducts,
						subItems: [
							{
								name: Store.language.string.cClientNav.reports,
								link: '/reports',
								disabled: !hasCompletedEsgProducts,
								hidden: !Store.user.shouldSeeReports || Store.business.isSubsidiary
							}
						]
					},

					// {
					// 	name: Store.language.string.cClientNav.productCO2e,
					// 	link: '/vueco2e',
					// 	icon: 'iconFont-productCO2e',
					// 	hidden: !Store.product.hasVueco2eProduct || Store.business.isSubsidiary,
					// 	subItems: [
					// 		{
					// 			name: Store.language.string.cClientNav.reports,
					// 			link: '/vueco2e/metrics',
					// 			hidden: !Store.user.isOwner
					// 		}
					// 	]
					// },

					// Vue Carbon
					{
						name: Store.language.string.cClientNav.productCarbon,
						link: '/vuecarbon',
						icon: 'iconFont-productVueCarbon',
						hidden: !Store.product.hasVueCarbonProducts,
						subItems: [
							{
								name: Store.language.string.cClientNav.reports,
								link: '/reports',
								disabled: !hasCompletedEsgProducts || !hasCompletedCarbonProducts,
								hidden: !Store.user.shouldSeeReports || Store.business.isSubsidiary
							}
						]
					},

					{
						name: Store.language.string.cClientNav.business,
						link: '/business',
						icon: 'iconFont-suitcase',
						subItems: [
							{
								name: Store.language.string.cClientNav.invoices,
								link: '/business/invoices',
								hidden: !hasPurchases || Store.business.isSubsidiary
							},
							{
								name: Store.language.string.cClientNav.businessCollaborators,
								link: '/business/collaborators',
								hidden: !Store.user.isOwner
							}
						]
					},
					{
						name: Store.language.string.cClientNav.support,
						link: '/support',
						icon: 'iconFont-help',
						subItems: [
							{
								name: Store.language.string.cClientNav.supportMessages,
								link: '/support/messages',
								notification:
									Core.Store.conversation.getLastMsg?.owner === 'theirs' &&
									!Core.Store.conversation.getLastMsg?.read
										? '!'
										: null
							},
							{
								name: Store.language.string.cClientNav.supportFAQ,
								link: '/support/faq'
							}
						]
					}
				];

				return config;
			});

			return { navConfig };
		}
	};
</script>

<style lang="scss"></style>
