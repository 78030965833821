<template>
	<div class="Conversations">
		<div class="row Box align-items-center">
			<div class="col-16 Box--title Space-bottomNone">
				<span class="Color-secondary">{{ language.string.cConversations.title }}</span>
				<span
					class="Color-tertiary"
					data-testid="conversations-toBusiness">
					{{ computedProps.toBusinessName }}
				</span>

				<div
					v-if="Core.Store.conversation.getActiveStatus.setAsRead"
					class="col-24 Conversations--setAsRead"
					data-testid="conversations-setAsRead">
					<span class="iconFont-simpleTick Text-tiny"></span>
					{{ language.string.cConversations.msgsRead }}
				</div>
			</div>

			<div class="col-8 Text-right">
				<transition name="fade">
					<button
						v-if="!state.showReplyForm"
						class="Btn Space-topNone Space-bottomNone"
						data-testid="conversations-writeMsgButton"
						@click="state.showReplyForm = true">
						{{
							messages.length
								? language.string.cConversations.btnReply
								: language.string.cConversations.btnNew
						}}
					</button>
				</transition>
			</div>

			<transition-group name="fade">
				<template v-if="state.showReplyForm">
					<blueprint-textarea
						id="sendMessage"
						ref="newMsgField"
						v-model:value="state.newMsg"
						class="col-24"
						:placeholder="language.string.cConversations.msgPlaceholder"
						data-testid="standard-input-required"
						:rows="10"
						:max-length="3000"
						required />

					<blueprint-checkbox
						v-if="listRelatedProducts.length > 0"
						id="relateMsg"
						v-model:value="state.relateMsg"
						class="col-18"
						:tooltip="language.string.cConversations.relatedTooltip"
						:label="language.string.cConversations.relatedCheckbox" />

					<div
						class="col-6"
						:class="!listRelatedProducts.length && 'offset-18'">
						<button
							class="Btn Btn-fullWidth"
							data-testid="conversations-sendMsgButton"
							@click="sendMsg">
							{{ language.string.cConversations.btnSend }}
						</button>
					</div>

					<!-- RELATE MSG TO PRODUCT SECTION -->
					<div
						v-show="state.relateMsg"
						class="col-6 Conversations--relatedDropdown Conversations--relatedDropdown-first">
						<blueprint-select
							id="relatedProduct"
							ref="productField"
							v-model:value="state.relatedProductId"
							:options="listRelatedProducts"
							:placeholder="language.string.cConversations.relatedProductPlaceholder"
							:required="state.relateMsg" />
					</div>
					<div
						v-show="state.relateMsg"
						class="col-6 Conversations--relatedDropdown">
						<blueprint-select
							id="relatedSection"
							ref="sectionField"
							v-model:value="state.relatedSectionName"
							:options="listRelatedSections"
							:placeholder="
								language.string.cConversations.relatedSectionPlaceholder
							" />
					</div>
				</template>
			</transition-group>

			<!-- DISPLAY ALL MESSAGES -->
			<blueprint-loader v-if="Core.Store.conversation.getLoadingState.messages" />
			<template v-else>
				<div
					v-for="(msg, index) in messages"
					:key="index"
					data-testid="conversations-message">
					<div
						class="Conversations--item"
						:class="'Conversations--item-' + msg.owner">
						<div class="row align-items-center">
							<div class="col-24">
								<img
									v-if="msg.senderBusinessId === 'omnevue'"
									class="Conversations--avatar"
									src="@Core/assets/logoSymbol.svg?url" />
								<p
									v-else
									class="Conversations--initials"
									data-testid="conversations-message-initials">
									{{ makeInitials(msg.sender) }}
								</p>

								<p
									class="Conversations--author"
									data-testid="conversations-message-author">
									{{ msg.sender }}, {{ msg.senderBusinessName }}

									<span
										class="Conversations--status"
										:class="msg.read && 'is-read'"
										data-testid="conversations-message-status">
										{{ msg.read ? 'Message Read' : 'Message Delivered' }}
										<span
											:class="
												msg.read
													? 'iconFont-tick'
													: 'iconFont-simpleTick Text-tiny'
											"></span>
									</span>
								</p>
								<p
									class="Conversations--time"
									data-testid="conversations-message-time">
									{{ msg.readableTime }}
								</p>
							</div>

							<div
								class="col-24 Conversations--body"
								data-testid="conversations-message-body">
								{{ msg.content.message }}
							</div>

							<div
								v-if="msg.meta?.relatedProduct"
								class="col-24 Conversations--relatedProduct">
								<router-link
									:to="
										linkRelated ? `/vueesg/${msg.meta?.relatedProduct.id}` : ''
									">
									<blueprint-pill
										:title="
											convertProductId(msg.meta?.relatedProduct.id)?.name ||
											msg.meta?.relatedProduct.id
										"
										color="secondary"
										:class="
											computedProps.linkRelated &&
											'Conversations--relatedLink'
										"
										data-testid="conversations-relatedPill-product" />
								</router-link>

								<router-link
									:to="
										linkRelated
											? `/vueesg/${msg.meta?.relatedProduct.id}/${msg.meta?.relatedProduct.section}`
											: ''
									">
									<blueprint-pill
										v-if="msg.meta?.relatedProduct.section"
										:title="
											convertSectionItem(msg.meta?.relatedProduct.section)
												?.name || msg.meta?.relatedProduct.section
										"
										color="secondary"
										:class="
											computedProps.linkRelated &&
											'Conversations--relatedLink'
										"
										data-testid="conversations-relatedPill-section" />
								</router-link>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="!computedProps.messages.length"
					data-testid="conversations-noMessages">
					{{ language.string.cConversations.noMessages }}
				</div>
			</template>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'Conversations',

		//  ---------- PROPS ----------
		props: {
			/**
			 * PROP: fromBusinessId sender business id
			 * @namespace Core_Conversations
			 * @property {boolean} fromBusinessId sender business id
			 */
			fromBusinessId: {
				type: String,
				required: true
			},

			/**
			 * PROP: name of business we are chatting with (not used for messages object)
			 * @namespace Core_Conversations
			 * @property {boolean} toBusinessName name of business we are chatting with (not used for messages object)
			 */
			toBusinessName: {
				type: String,
				required: true
			},

			/**
			 * PROP: [messages=[]] we want to display, current conversation history
			 * @namespace Core_Conversations
			 * @property {Array} [messages=[]] we want to display, current conversation history
			 */
			messages: {
				type: Array,
				required: false,
				default: () => []
			},

			/**
			 * PROP: [products={}] array from the store for the given business
			 * @namespace Core_Conversations
			 * @property {object} [products={}] array from the store for the given business
			 */
			products: {
				type: Object,
				required: false,
				default: () => {}
			},

			/**
			 * PROP: [linkRelated=true] link related product and section directly to pages
			 * @namespace Core_Conversations
			 * @property {boolean} [linkRelated=true] link related product and section directly to pages
			 */
			linkRelated: {
				type: Boolean,
				required: false,
				default: true
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const language = useLanguageStore();

			const newMsgField = Core.Vue.ref();
			const productField = Core.Vue.ref();
			const sectionField = Core.Vue.ref();

			const computedProps = Core.Vue.computed(() => {
				return {
					...props // make all props reactive
				};
			});

			// default state that will be used for resets
			const defaultState = {
				newMsg: '',
				relateMsg: false,
				relatedProductId: null,
				relatedSectionName: null,
				showReplyForm: false
			};

			// current state
			const state = Core.Vue.reactive({ ...defaultState });

			// hide reply field if channel changes
			Core.Vue.watch(
				() => Core.Store.conversation.getActiveStatus.channelId,
				() => {
					Object.assign(state, defaultState); // reset state
				}
			);

			Core.Vue.onUnmounted(() => {
				Core.Store.conversation.cancelReadAction();
			});

			// convert products object into options array for dropdown
			const listRelatedProducts = Core.Vue.computed(() => {
				const finalOptions = [];

				const products = computedProps.value.products;
				for (const item in products) {
					finalOptions.push({
						name: `vueesg ${products[item].financialYear}`,
						value: item
					});
				}

				return finalOptions;
			});

			const listRelatedSections = [
				{
					name: 'Accounting',
					value: 'integrations'
				},
				{
					name: 'Sales',
					value: 'sales'
				},
				{
					name: 'Facilities',
					value: 'facilities'
				},
				{
					name: 'Machinery',
					value: 'machinery'
				},
				{
					name: 'Logistics',
					value: 'logistics'
				},
				{
					name: 'Products & Packaging',
					value: 'products'
				},
				{
					name: 'Supply Chain',
					value: 'supply-chain'
				},
				{
					name: 'Customer data',
					value: 'customers'
				},
				{
					name: 'Employee Data',
					value: 'hr'
				},
				{
					name: 'Tax',
					value: 'risk-compliance'
				},
				{
					name: 'Board Composition',
					value: 'board-composition'
				},
				{
					name: 'Plans & Policies',
					value: 'policies'
				},
				{
					name: 'Supporting information',
					value: 'support'
				}
			];

			/**
			 * converts product id into actually FY name
			 * @param {string} productId id of the product from the message
			 * @returns {object} correct option from dropdown or empty object
			 */
			function convertProductId(productId) {
				for (const item in listRelatedProducts.value) {
					if (listRelatedProducts.value[item].value === productId) {
						return listRelatedProducts.value[item];
					}
				}

				return {};
			}

			/**
			 * converts section id into actually FY name
			 * @param {string} sectionId id of the section from the message
			 * @returns {object} correct option from dropdown or empty object
			 */
			function convertSectionItem(sectionId) {
				for (const item in listRelatedSections) {
					if (listRelatedSections[item].value === sectionId) {
						return listRelatedSections[item];
					}
				}

				return {};
			}

			/**
			 * Publishes the new message entered
			 */
			function sendMsg() {
				if (newMsgField.value.validate() && productField.value.validate()) {
					// do not set unless there are data
					let relatedProduct;
					if (state.relatedProductId) {
						relatedProduct = {
							id: state.relatedProductId,
							section: state.relatedSectionName
						};
					}

					Core.Tracking.event(
						'conversations-sendMessage',
						state.relatedProductId ? 'has related product' : ''
					);

					// send message
					Core.Store.conversation.publishMessage(state.newMsg, {
						relatedProduct
					});

					Object.assign(state, defaultState); // reset state
				}
			}

			/**
			 * Make initials out of full name
			 * @param {string} name of the author
			 * @returns {string} initials
			 */
			function makeInitials(name) {
				if (name) {
					const splitName = name.split(' ');
					return (
						splitName[0].charAt(0).toUpperCase() + splitName[1]?.charAt(0).toUpperCase()
					);
				}
				return;
			}

			return {
				Core,
				state,
				computedProps,
				sendMsg,
				makeInitials,
				newMsgField,
				listRelatedSections,
				listRelatedProducts,
				convertSectionItem,
				convertProductId,
				productField,
				sectionField,
				language
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('Conversations') {
		@include element('setAsRead') {
			font-size: var(--text-tiny);
			color: var(--color-grey600);
			font-weight: var(--weight-regular);

			& .iconFont-simpleTick {
				margin-right: 5px;
			}
		}

		@include element('relatedDropdown') {
			margin-top: -30px;

			@include modifier('first') {
				margin-left: 28px;
			}
		}

		@include element('item') {
			margin-bottom: var(--space-double);
			margin-top: var(--space-double);

			@include modifier('mine') {
			}

			/* modifiers existing classes when message is "theirs" */
			@include modifier('theirs') {
				& .Conversations--status {
					display: none;
				}
			}
		}
		@include element('initials') {
			background-color: var(--color-secondary);
			border-radius: 50%;
			color: var(--color-quaternary);
			display: inline-block;
			height: 40px;
			line-height: 40px;
			text-align: center;
			width: 40px;
			margin-right: var(--space-single);
			float: left;
			margin-top: 5px;
		}
		@include element('avatar') {
			display: inline-block;
			border-radius: 50%;
			height: 40px;
			line-height: 40px;
			width: 40px;
			margin-right: var(--space-single);
			float: left;
			margin-top: 3px;
		}
		@include element('author') {
			font-weight: var(--weight-bold);
			font-size: var(--text-large);
			vertical-align: top;
			color: var(--color-secondary);
		}
		@include element('time') {
			font-size: var(--text-small);
			opacity: 0.7;
		}
		@include element('body') {
			margin-top: var(--space-single);
			white-space: break-spaces;
			padding-left: 70px;
		}

		// related product/section styles
		@include element('relatedProduct') {
			margin-top: var(--space-single);
			padding-left: 70px;
		}
		@include element('relatedLink') {
			cursor: pointer;

			&:hover {
				background-color: var(--color-grey900);
			}
		}

		@include element('status') {
			float: right;
			vertical-align: bottom;
			font-size: var(--text-small);
			color: var(--color-grey700);
			font-weight: var(--weight-regular);

			&.is-read {
				color: var(--color-stateSuccess);

				& span {
					padding-bottom: 2px;
					vertical-align: bottom;
					display: inline-block;
				}
			}
		}
	}
</style>
