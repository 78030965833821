import * as Pinia from 'pinia';
import * as Pubnub from '@Core/modules/Pubnub/index.js';
import * as Utils from '@Core/modules/Utils/index.js';

const BUSINESS_OMNEVUE_CHANNEL_PREFIX = 'conversations-b-to-o.'; // sme - omnevue
const READ_ACTION_TIMEOUT = 5000; // time it will take to tag open messages as "read"

let pubnub = null; // pubnub object required to interact with it
let listener = null; // when chanmel active, it holds listener for different actions
let readTimeout = null; // after retrieving stuff, it will be set, but might be cancalled at certain situations

/* @todo
- This store instead of being initialised by Core, probably could be done by the apps themselves. Expose useConversationStore(). Challenge: Core Conversations components also needs to access it (probably should be passed-in as prop?)
- all product section should not allow to see sections if accounting is not completed - fix bug
- allow deleting last unread message (if unread!) hard delete
- phorge fetchMessages() has limit of 500 channels in one go, so this needs to be extended.
- use token's channel access, rather than generting all channels in phorge manually and then passing into getting channels history
*/

const defaultState = {
	data: {
		messages: [], // messages for currently selected channel
		channels: [], // all channels messages
		active: {
			channelId: null,
			toBusinessId: null,
			senderName: null,
			senderBusinessId: null,
			senderBusinessName: null,
			setAsRead: false
		},
		loading: {
			pubnub: true,
			messages: false,
			channels: false
		}
	}
};

/**
 * @exports Core_Store_Conversation
 * @namespace Core_Store_Conversation
 */
export const useConversationStore = Pinia.defineStore({
	id: 'conversation',

	state: () => ({ ...defaultState }),

	actions: {
		/**
		 * Connects and sets up messaging system
		 * @memberof Core_Store_Conversation
		 * @param {string} senderName first and last name likely
		 * @param {string} senderBusinessId their business id (used to create channels later)
		 * @param {string} senderBusinessName displayed on each message and channel names
		 */
		connect(senderName, senderBusinessId, senderBusinessName) {
			if (!senderName || !senderBusinessId || !senderBusinessName || pubnub) {
				return;
			}

			this.data.active.senderName = senderName;
			this.data.active.senderBusinessId = senderBusinessId;
			this.data.active.senderBusinessName = senderBusinessName;

			// connect
			pubnub = Pubnub.pubnub;

			// add listeners
			listener = {
				status: (statusEvent) => {
					app.log('MESSAGE', 'Status', statusEvent.category);
				},
				message: (messageEvent) => {
					app.log('MESSAGE', 'New Message');
					this.createMessage(messageEvent);
				},
				messageAction: (messageEvent) => {
					app.log('MESSAGE', 'New Message Action');

					if (messageEvent.data.type === 'read') {
						this.getMsgByToken(messageEvent.data.messageTimetoken).read = true;
					}
					if (messageEvent.data.type === 'deleted') {
						this.getMsgByToken(messageEvent.data.messageTimetoken).deleted = true;
					}
				}
			};
			pubnub.addListener(listener);
			this.data.loading.pubnub = false;
		},

		/**
		 * It will disconnect conversations from the current channels
		 * It also wipes out the state (all of it!)
		 * @memberof Core_Store_Conversation
		 */
		disconnect() {
			if (listener) {
				pubnub.removeListener(listener); // removes listener if it was set for previous channel
			}
			pubnub = null;
			Object.assign(this, defaultState);
		},

		/**
		 * Creates new channel with a business and pull down the history
		 * @memberof Core_Store_Conversation
		 * @param {string|null} [channelBusinessId=active.senderBusinessId] used for channel identifications (only needs to be specified when user can access multiple channels)
		 * @param {number} [limit=100] limit of messages to pull in
		 * @param {boolean} [readOnLoad=true] it will automatically trigger to mark channel as read
		 */
		async retrieveMessages(channelBusinessId, limit = 0, readOnLoad = true) {
			const correctBusinessId =
				typeof channelBusinessId === 'string'
					? channelBusinessId
					: this.data.active.senderBusinessId;

			// clean stuff
			this.data.loading.messages = true;
			this.data.messages = [];
			this.data.active.channelId = `${BUSINESS_OMNEVUE_CHANNEL_PREFIX}${correctBusinessId}`;
			this.data.active.toBusinessId = correctBusinessId;
			this.data.active.setAsRead = false;
			// subscribe to new
			await pubnub.subscribe({
				channels: [this.data.active.channelId]
			});

			await this.retrieveHistory(limit);
			this.data.loading.messages = false;

			// set as read
			if (readOnLoad) {
				readTimeout = setTimeout(() => {
					this.markChannelRead();
				}, READ_ACTION_TIMEOUT);
			}
		},

		/**
		 * Clears "mark as read" timeout which might have been triggered after pulling in all messages
		 * @memberof Core_Store_Conversation
		 */
		cancelReadAction() {
			clearTimeout(readTimeout);
		},

		/**
		 * Retrieve all active channels for businesses ids
		 * @memberof Core_Store_Conversation
		 * @param {Array<string>} businessIds of all businesses you want to check channel for
		 */
		async retrieveBusinessChannels(businessIds) {
			this.data.loading.channels = true;
			if (!this.data.loading.pubnub) {
				this.data.channels = []; // reset current data in store

				const results = await pubnub.fetchMessages({
					channels: businessIds.map((x) => `${BUSINESS_OMNEVUE_CHANNEL_PREFIX}${x}`),
					count: 1
				});

				// go through each channel
				for (const item in results.channels) {
					const businessId = item.replace(BUSINESS_OMNEVUE_CHANNEL_PREFIX, '');
					const channelObject = {
						businessId: businessId,
						channel: item,
						messages: []
					};

					// go through multiple messages for each channel
					for (const message in results.channels[item]) {
						const newMsg = results.channels[item][message];
						const convertedTime = Math.trunc(
							results.channels[item][message].timetoken / 10000
						);

						newMsg.readableTime = Utils.humanTime(
							new Date(convertedTime).toISOString(),
							true,
							'short'
						);
						channelObject.messages.push(newMsg);
					}

					this.data.channels.push(channelObject);
				}

				this.data.loading.channels = false;
			} else {
				setTimeout(() => {
					this.retrieveBusinessChannels(businessIds);
				}, 1000);
			}
		},

		/**
		 * Retrieve history messages for currently active channel
		 * @memberof Core_Store_Conversation
		 * @param {number} [limit=100] limit of messages to pull in, 0 = means no limit
		 */
		async retrieveHistory(limit = 100) {
			const results = await pubnub.fetchMessages({
				channels: [this.data.active.channelId],
				includeMessageActions: true,
				count: limit
			});

			const messages = results.channels[this.data.active.channelId];

			if (messages) {
				for (let i = 0; i < messages.length; i++) {
					this.createMessage(messages[i]);
				}
			}
		},

		/**
		 * Create message for any given channel
		 * @memberof Core_Store_Conversation
		 * @param {object} messageObject object from PubNub
		 */
		createMessage(messageObject) {
			const convertedTime = Math.trunc(messageObject.timetoken / 10000); // pubnub is using weird format for their timestamp
			const finalMsg = {
				timetoken: messageObject.timetoken,
				readableTime: Utils.humanTime(new Date(convertedTime).toISOString()),
				read: messageObject.data?.read ? true : false,
				deleted: messageObject.data?.deleted ? true : false,
				owner:
					messageObject.message.senderBusinessId === this.data.active.senderBusinessId
						? 'mine'
						: 'theirs',
				...messageObject.message
			};

			this.data.messages.push(finalMsg);
		},

		/**
		 * Publish message to the channel (send to PubNub)
		 * @memberof Core_Store_Conversation
		 * @param {string} body text in default input field format
		 * @param {object} [meta] data to be included in the message
		 */
		async publishMessage(body, meta) {
			const publishPayload = {
				channel: this.data.active.channelId,
				message: {
					content: {
						type: 'text',
						message: body
					},
					sender: this.data.active.senderName,
					senderBusinessId: this.data.active.senderBusinessId,
					senderBusinessName: this.data.active.senderBusinessName,
					meta
				}
			};

			await pubnub.publish(publishPayload);
			this.data.active.setAsRead = false; // reset it, in case it was already read
			this.markChannelRead();
			this.updateChannel(publishPayload);
		},

		/**
		 * Add the last message to channels list
		 * Channels is populated (sometimes) with multiple channels and contain the last message from each. When new message is published, we should update the particular channel.
		 * If channels are not populated, then we totally skip any work here (we have to loop through them, though)
		 * @memberof Core_Store_Conversation
		 * @param {object} message object we are publishing
		 */
		updateChannel(message) {
			for (const item in this.data.channels) {
				const currentChannel = this.data.channels[item];
				if (currentChannel.channel === message.channel) {
					currentChannel.messages.pop();
					currentChannel.messages.push(message);
					break;
				}
			}
		},

		/**
		 * It will mark currently active channel as read (it will update all messages and status "read" on their messages only)
		 * @memberof Core_Store_Conversation
		 */
		markChannelRead() {
			let messagesUpdated = 0;

			for (let i = 0; i < this.data.messages.length; i++) {
				const message = this.data.messages[i];
				if (message.owner === 'theirs' && !message.read) {
					pubnub.addMessageAction({
						channel: this.data.active.channelId,
						messageTimetoken: message.timetoken,
						action: {
							type: 'read',
							value: 'userDetails'
						}
					});
					messagesUpdated++;
				}
			}

			// only if at least one message has been updated
			if (messagesUpdated > 0) {
				this.data.active.setAsRead = true;
			}
		},

		/**
		 * It adds actions "deleted" to the given message treating is as "Soft deleted" for the given active channel (flag added).
		 * It will stay in the system forever, but will be ignored by the store as default.
		 * @memberof Core_Store_Conversation
		 * @param {string} timetoken of the given message
		 */
		async deleteMessageSoft(timetoken) {
			await pubnub.addMessageAction({
				channel: this.data.active.channelId,
				messageTimetoken: timetoken,
				action: {
					type: 'deleted',
					value: 'userDetails'
				}
			});
		},

		/**
		 * It will physically deletes a message within +/- 1 from provided timetoken for the given active channel
		 * This operation is expensive so use carefully!
		 * @memberof Core_Store_Conversation
		 * @param {string} timetoken of the given message
		 */
		async deleteMessageHard(timetoken) {
			const token = parseInt(timetoken);
			const start = token - 1;
			const end = token + 1;

			await pubnub.deleteMessages({
				channel: this.data.active.channelId,
				start: start,
				end: end
			});

			// TODO: should delete message from store data???
		}
	},

	getters: {
		/**
		 * Get all messages for currently active business channel
		 * @memberof Core_Store_Conversation
		 * @param {object} state automatically passed in
		 * @returns {Array} messages (excludes soft-deleted ones)
		 */
		getMessages: (state) => {
			const activeMessages = state.data.messages.filter((msg) => !msg.deleted);
			return activeMessages.toReversed();
		},

		/**
		 * Get currently active business for the business channel connected
		 * @memberof Core_Store_Conversation
		 * @param {object} state automatically passed in
		 * @returns {string} businessId
		 */
		getActiveStatus: (state) => {
			return state.data.active;
		},

		/**
		 * Get loading states for all options
		 * @memberof Core_Store_Conversation
		 * @param {object} state automatically passed in
		 * @returns {string} loading state from store
		 */
		getLoadingState: (state) => {
			return state.data.loading;
		},

		/**
		 * Get all active business channels
		 * @memberof Core_Store_Conversation
		 * @param {object} state automatically passed in
		 * @returns {object} with channels and array of messages
		 */
		getBusinessChannels: (state) => {
			return state.data.channels.sort((a, b) => {
				return a.messages[0].timetoken < b.messages[0].timetoken;
			});
		},

		/**
		 * Will give you details about the last message
		 * @memberof Core_Store_Conversation
		 * @param {object} state automatically passed in
		 * @returns {object} with message details (not a typical msg object)
		 */
		getLastMsg: (state) => {
			const lastMsg = state.data.messages[state.data.messages.length - 1];
			return !lastMsg?.deleted ? lastMsg : {};
		},

		/**
		 * Get single message by time token
		 * @memberof Core_Store_Conversation
		 * @param {object} state automatically passed in
		 * @returns {object} message object
		 */
		getMsgByToken: (state) => (timetoken) => {
			const message = state.data.messages.filter((msg) => msg.timetoken === timetoken);
			return message[0] || {};
		}
	}
});
