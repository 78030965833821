import { transformSingleValues, transformMonthlyValues, transformSummaryValues } from './utils';

/**
 * Backend object for metrics API endpoint
 * @typedef {Array} Metric-Dataset
 * @property {string} id - of the dataset, so returned data can be correctly tagged with given IDs
 * @property {('MONTHLY'|'SUMMARY'|'ETC...')} dataset - type of data requested
 * @property {Array} metrics - array of metrics (strings) to be pulled in from the given dataset
 * @property {object} criteria - {[businessId], productId} to be pulled in
 */

/**
 * Pre-config for data metrics pulls
 * @namespace Client_Reports_Metrics
 * @type {object}
 */
export const METRICS_CONFIG = {
	tasks: {
		dataset: 'TASKS',
		metrics: ['section']
	},
	insights: {
		dataset: 'INSIGHTS',
		metrics: ['section']
	},
	assure: {
		dataset: 'ANNUAL',
		metrics: ['assure_revenue', 'assure_grossProfit', 'assure_eBITDA'],
		method: transformSingleValues
	},
	energy: {
		dataset: 'MONTHLY',
		metrics: ['assure_nonRenewableEnergyKwh', 'assure_renewableEnergyKwh'],
		method: transformMonthlyValues
	},
	totalNonRenewableEnergy: {
		dataset: 'ANNUAL',
		metrics: ['assure_nonRenewableEnergyKwh']
	},
	totalRenewableEnergy: {
		dataset: 'ANNUAL',
		metrics: ['assure_renewableEnergyKwh']
	},
	esgImpact: {
		dataset: 'ANNUAL',
		metrics: ['assure_esgImpact'],
		method: transformSummaryValues
	},
	energySuppliers: {
		dataset: 'ENERGY_SUPPLIERS',
		metrics: [
			'analyse_supplier',
			'analyse_energyKwh',
			'analyse_renewableEnergyKwh',
			'analyse_renewableEnergyPercentage',
			'analyse_cO2e'
		]
	},
	energySuppliersChartData: {
		dataset: 'ENERGY_SUPPLIERS',
		metrics: ['analyse_supplier', 'analyse_nonRenewableEnergyKwh', 'analyse_renewableEnergyKwh']
	},
	customerData: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_numberOfGDPRBreachesDataPrivacySecurity',
			'assure_complaints_percentage_sales',
			'assure_numberOfSales',
			'assure_customerComplaints'
		],
		method: transformSingleValues
	},
	customerDataValueRisk: {
		dataset: 'ANNUAL',
		metrics: ['analyse_sValueLossToCustomerComplaints'],
		method: transformSingleValues
	},
	customerDataGdprValueRisk: {
		dataset: 'ANNUAL',
		metrics: ['analyse_sGdprFinesOf2Percentage4PercentageOfGlobalTurnover'],
		method: transformSingleValues
	},
	water: {
		dataset: 'MONTHLY',
		metrics: ['assure_waterUsageM3'],
		method: transformMonthlyValues
	},
	totalWaterAnnual: {
		dataset: 'ANNUAL',
		metrics: ['assure_waterUsageM3'],
		method: transformSummaryValues
	},
	totalWaterCo2Summary: {
		dataset: 'ANNUAL',
		metrics: ['assure_waterTotalsWaterCo2']
	},
	waterCo2: {
		dataset: 'MONTHLY',
		metrics: ['analyse_co2eOnWater'],
		method: transformMonthlyValues
	},
	waterIntensity: {
		dataset: 'ANNUAL',
		metrics: ['analyse_waterIntensityOnRevenuePer1000OfRevenue'],
		method: transformSummaryValues
	},
	co2Summary: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_cO2eFromFuelCombustionInBoilersFurnacesInBusiness',
			'analyse_cO2eFromCompanyVehicles',
			'analyse_cO2eEmissionsFromFactoryOffice',
			'analyse_cO2eFromCapitalGoods',
			'analyse_cO2eFromWasteGeneratedInOperations',
			'analyse_cO2eFromBusinessTravel',
			'analyse_cO2eFromEmployeeCommuting',
			'analyse_cO2eFromFinancialInvestments'
		]
	},
	totalCo2Vehicles: {
		dataset: 'ANNUAL',
		metrics: ['assure_totalsCo2Vehicles']
	},
	co2MachineryFuel: {
		dataset: 'MACHINERY_FUELS',
		metrics: ['assure_fuelType', 'assure_fuelAmountUnits', 'assure_fuelCO2e'],
		method: transformSummaryValues
	},
	co2MachineryRefrigerants: {
		dataset: 'MACHINERY_REFRIGERANTS',
		metrics: [
			'assure_refrigerantType',
			'assure_refrigerantAmountUnits',
			'assure_refrigerantCO2e'
		],
		method: transformSummaryValues
	},
	co2ScopeSummary: {
		dataset: 'ANNUAL',
		metrics: ['analyse_totalScope1', 'analyse_totalScope2', 'analyse_totalScope3CO2e']
	},
	totalCo2Summary: {
		dataset: 'ANNUAL',
		metrics: ['assure_totalCo2Summary'],
		method: transformSingleValues
	},
	energySpend: {
		dataset: 'MONTHLY',
		metrics: ['assure_spendOnElectricity', 'assure_spendOnGas'],
		method: transformMonthlyValues
	},
	energyUsage: {
		dataset: 'MONTHLY',
		metrics: ['assure_nonRenewableEnergyKwh', 'assure_renewableEnergyKwh'],
		method: transformMonthlyValues
	},
	co2Intensity: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_emissionsIntensityOnRevenueScope1And2',
			'analyse_emissionsIntensityOnRevenueScope3',
			'analyse_emissionsIntensityOnRevenueAll'
		],
		method: transformSummaryValues
	},
	co2EmployeesIntensity: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_emissionsIntensityOnNumberOfStaffScope1And2',
			'analyse_emissionsIntensityOnNumberOfStaffScope3',
			'analyse_emissionsIntensityOnNumberOfStaffAll'
		],
		method: transformSummaryValues
	},
	co2Scope1: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_cO2eFromFuelCombustionInBoilersFurnacesInBusiness',
			'analyse_cO2eFromCompanyVehicles',
			'analyse_cO2eFugitiveEmissions'
		],
		method: transformSummaryValues
	},
	co2Scope2: {
		dataset: 'ANNUAL',
		metrics: ['analyse_cO2eEmissionsFromFactoryOffice'],
		method: transformSummaryValues
	},
	co2Scope3: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_cO2ePurchasedGoodsServices',
			'analyse_cO2eFromCapitalGoods',
			'analyse_cO2eFuelEnergyActivities',
			'analyse_cO2eUpstreamTransportationDistribution',
			'analyse_cO2eFromWasteGeneratedInOperations',
			'analyse_cO2eFromBusinessTravel',
			'analyse_cO2eFromEmployeeCommuting',
			'analyse_cO2eUpstreamLeasedAssets',
			'analyse_cO2eDownstreamTransportationDistribution',
			'analyse_cO2eProcessingSoldProducts',
			'analyse_cO2eUseSoldProducts',
			'analyse_cO2eEndLifeTreatmentSoldProducts',
			'analyse_cO2eDownstreamLeasedAssets',
			'analyse_cO2eFranchises',
			'analyse_cO2eFromFinancialInvestments'
		],
		method: transformSummaryValues
	},
	co2Facilities: {
		dataset: 'FACILITIES',
		metrics: [
			'assure_businessFacility',
			'assure_energyKwh',
			'assure_renewableEnergyKwh',
			'analyse_renewable_percentage',
			'analyse_cO2e'
		]
	},
	co2FacilitiesTotal: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_facilityTotalsEnergykWh',
			'assure_facilityTotalsRenewablekWh',
			'assure_facilityTotalsRenewablePercentage',
			'assure_facilityTotalsCo2'
		]
	},
	co2FacilitiesChart: {
		dataset: 'FACILITIES',
		metrics: ['analyse_cO2e']
	},
	employeesCommutingFacilities: {
		dataset: 'FACILITIES',
		metrics: ['assure_businessFacility', 'assure_employeeCount', 'analyse_cO2ePerEmployee'],
		method: transformSummaryValues
	},
	employeeCommutingFacilitiesTotal: {
		dataset: 'ANNUAL',
		metrics: ['assure_facilityTotalsEmployeeNumber', 'assure_facilityTotalsEmployeeCo2'],
		method: transformSummaryValues
	},
	waterFacilities: {
		dataset: 'FACILITIES',
		metrics: ['assure_businessFacility', 'assure_waterM3', 'analyse_water_CO2e'],
		method: transformSummaryValues
	},
	waterFacilitiesTotals: {
		dataset: 'ANNUAL',
		metrics: ['assure_waterUsageM3', 'assure_waterTotalsWaterCo2'],
		method: transformSingleValues
	},
	waste: {
		dataset: 'MONTHLY',
		metrics: ['assure_nonRecyclableWasteTonnes', 'assure_recyclableWasteTonnes'],
		method: transformMonthlyValues
	},

	wasteTotals: {
		dataset: 'ANNUAL',
		metrics: ['assure_recyclableWasteTonnes', 'assure_nonRecyclableWasteTonnes']
	},
	wasteIntensity: {
		dataset: 'ANNUAL',
		metrics: ['analyse_wasteIntensityOnRevenuePer1000OfRevenue'],
		method: transformSingleValues
	},
	wasteFacilities: {
		dataset: 'FACILITIES',
		metrics: [
			'assure_businessFacility',
			'assure_wasteTonnes',
			'assure_recyclableWasteTonnes',
			'analyse_recyclingPercentage',
			'analyse_waste_CO2e'
		]
	},
	wasteFacilitiesTotals: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_wasteTonnes',
			'assure_recyclableWasteTonnes',
			'assure_wasteTotalsRecyclingPercentage',
			'assure_wasteTotalsWasteCo2'
		]
	},
	wasteCo2e: {
		dataset: 'MONTHLY',
		metrics: ['analyse_co2eOnWaste'],
		method: transformMonthlyValues
	},
	totalWasteAnnual: {
		dataset: 'ANNUAL',
		metrics: ['assure_wasteTonnes']
	},
	totalWasteCo2Annual: {
		dataset: 'ANNUAL',
		metrics: ['assure_wasteTotalsWasteCo2']
	},
	utilsSpend: {
		dataset: 'MONTHLY',
		metrics: [
			'assure_spendOnElectricity',
			'assure_spendOnGas',
			'assure_spendOnWater',
			'assure_spendOnWaste'
		],
		method: transformMonthlyValues
	},
	totalTravelAnnualKm: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_estimatedBusinessTravelByPlaneKmAnnual',
			'assure_estimatedBusinessTravelByTrainKmAnnual',
			'assure_estimatedBusinessTravelByTaxiKmAnnual'
		],
		method: transformMonthlyValues
	},

	totalCo2eTravelAnnual: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_co2eOnTravelByPlaneAnnual',
			'analyse_co2eOnTravelByTrainAnnual',
			'analyse_co2eOnTravelByTaxiAnnual'
		],
		method: transformMonthlyValues
	},

	vehiclesTypeAndFuel: {
		dataset: 'VEHICLES',
		metrics: ['assure_vehicleType', 'assure_businessVehicle', 'assure_numberOfVehiclesCount']
	},
	distanceAndCo2PerVehicle: {
		dataset: 'VEHICLES',
		metrics: [
			'assure_vehicleType',
			'analyse_distanceTravelledPerVehicleKm',
			'analyse_cO2eEmissionPerVehicleGroup',
			'assure_businessVehicle'
		]
	},
	co2PerVehicleGroup: {
		dataset: 'VEHICLES',
		metrics: ['analyse_cO2eEmissionPerVehicleGroup'],
		method: transformSummaryValues
	},
	co2ByBusinessTravel: {
		dataset: 'ANNUAL',
		metrics: ['analyse_cO2eFromBusinessTravel'],
		method: transformSummaryValues
	},
	assureGenderTotal: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_totalMaleEmployeesGenderSplitByLevel',
			'assure_totalFemaleEmployeesGenderSplitByLevel',
			'assure_totalOtherEmployeesGenderSplitByLevel',
			'assure_totalNDGenderSplitByLevel'
		],
		method: transformSingleValues
	},
	assureGenderMale: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalMaleEmployeesGenderSplitByLevel',
			'assure_managerialMaleEmployeesGenderSplitByLevel',
			'assure_seniorManagementMaleEmployeesGenderSplitByLevel'
		],
		method: transformSingleValues
	},
	assureGenderFemale: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalFemaleEmployeesGenderSplitByLevel',
			'assure_managerialFemaleEmployeesGenderSplitByLevel',
			'assure_seniorManagementFemaleEmployeesGenderSplitByLevel'
		],
		method: transformSingleValues
	},
	assureGenderOther: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalNDGenderSplitByLevel',
			'assure_managerialNDGenderSplitByLevel',
			'assure_seniorManagementNDGenderSplitByLevel'
		],
		method: transformSingleValues
	},
	assureGenderNonBinary: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalOtherEmployeesGenderSplitByLevel',
			'assure_managerialOtherEmployeesGenderSplitByLevel',
			'assure_seniorManagementOtherEmployeesGenderSplitByLevel'
		],
		method: transformSingleValues
	},
	genderDiversityFacility: {
		dataset: 'FACILITIES',
		metrics: [
			'assure_businessFacility',
			'assure_totalFemaleAndNonBinary',
			'assure_employeeCount',
			'analyse_genderSplit'
		],
		method: transformSummaryValues
	},
	// Pay gap ethnicity
	payGapEthnicity: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_paygapEthnicOperational',
			'assure_paygapEthnicManagerial',
			'assure_paygapEthnicSeniorManagerial',
			'assure_paygapEthnic'
		],
		method: transformSingleValues
	},
	// Pay gap gender
	payGapGender: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_paygapGenderOperational',
			'assure_paygapGenderManagerial',
			'assure_paygapGenderSeniorManagerial',
			'assure_paygapGender'
		],
		method: transformSingleValues
	},
	genderPayGapOperational: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalMaleAverageSalaryByLevel',
			'assure_operationalFemaleOtherAverageSalaryByLevel'
		],
		method: transformSingleValues
	},
	genderPayGapManagerial: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_managerialMaleAverageSalaryByLevel',
			'assure_managerialFemaleOtherAverageSalaryByLevel'
		],
		method: transformSingleValues
	},
	genderPayGapSeniorManagement: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_seniorManagementMaleAverageSalaryByLevel',
			'assure_seniorManagementFemaleOtherAverageSalaryByLevel'
		],
		method: transformSingleValues
	},
	genderPayGapTotal: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_totalMaleAverageSalaryByLevel',
			'assure_totalFemaleOtherAverageSalaryByLevel'
		],
		method: transformSingleValues
	},
	ethnicPayGapOperational: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalWhiteAverageSalary',
			'assure_operationalNonWhiteAverageSalary'
		],
		method: transformSingleValues
	},
	ethnicPayGapManagerial: {
		dataset: 'ANNUAL',
		metrics: ['assure_managerialWhiteAverageSalary', 'assure_managerialNonWhiteAverageSalary'],
		method: transformSingleValues
	},
	ethnicPayGapSeniorManagement: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_seniorManagementWhiteAverageSalary',
			'assure_seniorManagementNonWhiteAverageSalary'
		],
		method: transformSingleValues
	},
	ethnicPayGapTotal: {
		dataset: 'ANNUAL',
		metrics: ['assure_totalWhiteAverageSalary', 'assure_totalNonWhiteOtherAverageSalary'],
		method: transformSingleValues
	},
	ethnicPayGapAverage: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_totalAverageSalaryByEthnicityAndLevel',
			'assure_totalNonWhiteOtherAverageSalary'
		],
		method: transformSingleValues
	},
	assureEthnicTotal: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_whiteEthnicSplitByLevel',
			'assure_blackEthnicSplitByLevel',
			'assure_asianEthnicSplitByLevel',
			'assure_mixedEthnicSplitByLevel',
			'assure_otherEthnicSplitByLevel',
			'assure_NDEthnicSplitByLevel'
		],
		method: transformSingleValues
	},
	assureEthnicWhite: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalWhiteEthnicSplitByLevel',
			'assure_managerialWhiteEthnicSplitByLevel',
			'assure_seniorManagementWhiteEthnicSplitByLevel'
		],
		method: transformSingleValues
	},
	assureEthnicBlack: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalBlackEthnicSplitByLevel',
			'assure_managerialBlackEthnicSplitByLevel',
			'assure_seniorManagementBlackEthnicSplitByLevel'
		],
		method: transformSingleValues
	},
	assureEthnicAsian: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalAsianEthnicSplitByLevel',
			'assure_managerialAsianEthnicSplitByLevel',
			'assure_seniorManagementAsianEthnicSplitByLevel'
		],
		method: transformSingleValues
	},
	assureEthnicMixed: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalMixedEthnicSplitByLevel',
			'assure_managerialMixedEthnicSplitByLevel',
			'assure_seniorManagementMixedEthnicSplitByLevel'
		],
		method: transformSingleValues
	},
	assureEthnicNotDisclosed: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalNDEthnicSplitByLevel',
			'assure_managerialNDEthnicSplitByLevel',
			'assure_seniorManagementNDEthnicSplitByLevel'
		],
		method: transformSingleValues
	},
	assureEthnicOther: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalOtherEthnicSplitByLevel',
			'assure_managerialOtherEthnicSplitByLevel',
			'assure_seniorManagementOtherEthnicSplitByLevel'
		],
		method: transformSingleValues
	},
	employeeData: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_totalNumberOfEmployeesByLevel',
			'assure_joiners',
			'assure_leavers',
			'assure_netEmploymentCreation'
		],
		method: transformSingleValues
	},
	employeeSummary: {
		dataset: 'SUMMARY',
		metrics: ['analyse_sEmployeeSupport', 'analyse_sEmployeeTraining'],
		method: transformSummaryValues
	},
	employeeDataSalaries: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalAverageSalaryByLevel',
			'assure_managerialAverageSalaryByLevel',
			'assure_seniorManagementAverageSalaryByLevel',
			'assure_totalAverageSalaryByLevel'
		],
		method: transformSingleValues
	},
	employeeDataCount: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_operationalNumberOfEmployeesByLevel',
			'assure_managerialNumberOfEmployeesByLevel',
			'assure_seniorManagementNumberOfEmployeesByLevel'
		],
		method: transformSingleValues
	},
	employeeDataCostOfReplacingTalent: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_cORTOperational',
			'analyse_cORTManagerial',
			'analyse_cORTSeniorManagement',
			'analyse_cORTAverage'
		],
		method: transformSingleValues
	},
	employeeDataValueRisk: {
		dataset: 'ANNUAL',
		metrics: ['analyse_sChangeInEBITDAContributionPerEmployeeVsPriorYear'],
		method: transformSingleValues
	},
	boardComp: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_boardCompositionExecutive',
			'assure_boardCompositionNonExecutiveIndependent',
			'assure_boardCompositionIndependentAdvisor'
		],
		method: transformSingleValues
	},
	boardCompGender: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_boardGenderDiversityMale',
			'assure_boardGenderDiversityFemale',
			'assure_boardGenderDiversityGenderX'
		],
		method: transformSingleValues
	},
	interventions: {
		dataset: 'ANNUAL',
		metrics: ['assure_numberOfTaxInterventions'],
		method: transformSingleValues
	},
	// SAVED VALUE SHOULD GO LAST IN THE LIST
	valueWaste: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_eEstimatedSpendWithoutWasteRecycling',
			'analyse_eActualAmountSpentForWasteRecycling',
			'analyse_eValueSavedByWasteRecycling'
		],
		method: transformSummaryValues
	},
	valueWater: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_eEstimatedValueOfWaterUsage',
			'analyse_eActualValueOfWaterUsage',
			'analyse_eValueSavedInWaterUsageVsPriorYear'
		],
		method: transformSummaryValues
	},
	valueCO2Scope1: {
		dataset: 'ANNUAL',
		metrics: ['analyse_eValueSavedByReducingCO2EmissionsScope1vsPriorYear'],
		method: transformSummaryValues
	},
	valueCO2Scope2: {
		dataset: 'ANNUAL',
		metrics: ['analyse_eValueSavedByReducingCO2EmissionsScope2vsPriorYear'],
		method: transformSummaryValues
	},
	valueTravel: {
		dataset: 'ANNUAL',
		metrics: [
			'analyse_eEstimatedSpendWithoutElectricVehicles',
			'analyse_eActualSpendUsingElectricVehicles',
			'analyse_eValueSavedByUsingElectricVehicles'
		],
		method: transformSummaryValues
	},

	products: {
		dataset: 'PRODUCTS',
		metrics: [
			'assure_productDescription',
			'analyse_numberSoldCount',
			'analyse_averageDistanceMovedKm',
			'analyse_cO2ePerSingleProduct',
			'analyse_totalDistanceMoved',
			'analyse_totalCO2e'
		],
		method: transformSummaryValues
	},
	productCo2PerProduct: {
		dataset: 'PRODUCTS',
		metrics: ['analyse_totalCO2e'],
		method: transformSingleValues
	},
	totalCO2FromProducts: {
		dataset: 'ANNUAL',
		metrics: ['assure_totalCo2Products']
	},
	productMaterials: {
		dataset: 'PRODUCT_COMPONENTS',
		metrics: [
			'assure_productDescription',
			'assure_component',
			'assure_plasticKg',
			'assure_metalAluminiumCansAndFoilKg',
			'assure_metalOtherKg',
			'assure_electricalKg',
			'assure_glassKg',
			'assure_woodKg',
			'assure_clothingKg',
			'assure_paperKg',
			'assure_organicBiodegradableKg',
			'assure_rubberKg',
			'assure_totalKg',
			'analyse_numberUsedCount',
			'analyse_airKm',
			'analyse_roadKm',
			'analyse_seaKm',
			'analyse_totalKm',
			'analyse_distanceTravelledPerProduct',
			'analyse_cO2ePerComponent'
		],
		method: transformSingleValues
	},
	employeeFacilityEthnicity: {
		dataset: 'FACILITIES',
		metrics: [
			'assure_businessFacility',
			'assure_bameEmployeeCount',
			'assure_employeeCount',
			'analyse_ethnicityPercentage'
		],
		method: transformSummaryValues
	},
	// SAVED VALUE SHOULD GO LAST IN THE LIST
	scorecardE: {
		dataset: 'SUMMARY',
		metrics: [
			'analyse_eCO2EmissionsScope1And2',
			'analyse_eCO2EmissionsScope1',
			'analyse_eCO2EmissionsScope2',
			'analyse_eSupplyChainCO2Scope3',
			'analyse_eWasteRecycling',
			'analyse_eWaterUsage',
			'analyse_eEnergyEfficientVehicles'
		],
		method: transformSummaryValues
	},
	scorecardS: {
		dataset: 'SUMMARY',
		metrics: [
			'analyse_sSalariesAndWages',
			'analyse_sEmployeeSupport',
			'analyse_sEmployeeTraining',
			'analyse_sGenderDiversity',
			'analyse_sEthnicDiversity',
			'analyse_sGenderPayGap',
			'analyse_sCEOSalaryGap',
			'analyse_sSupplyChainESG',
			'analyse_sCustomerComplaints'
		],
		method: transformSummaryValues
	},
	scorecardG: {
		dataset: 'SUMMARY',
		metrics: [
			'analyse_gExternalAndIndependentAdvisors',
			'analyse_gBoardGenderDiversity',
			'analyse_gTaxInterventions',
			'analyse_sManagementOfPersonalData',
			'analyse_pUNGCOECDMonitoring',
			'analyse_pWhistleblower',
			'analyse_pNetZeroPlan',
			'analyse_pBiodiversityPlan'
		],
		method: transformSummaryValues
	},
	policies: {
		dataset: 'ANNUAL',
		metrics: [
			'assure_antiSlavery',
			'assure_whistleblower',
			'analyse_netZeroPlan',
			'analyse_biodiversityPlan'
		],
		method: transformSingleValues
	},
	suppliersList: {
		dataset: 'SUPPLIERS',
		metrics: [
			'analyse_supplierName',
			'analyse_amountSpent',
			'analyse_supplierCO2e',
			'analyse_CO2ecalculation',
			'analyse_vettedForESG'
		],
		method: transformSummaryValues
	},
	supplyChain: {
		dataset: 'MOVEMENT_IN_SUPPLY_CHAIN',
		metrics: [
			'analyse_from',
			'analyse_to',
			'analyse_deliveryType',
			'analyse_deliveriesCount',
			'analyse_distanceBetweenAAndBKm',
			'analyse_totalCO2e'
		],
		method: transformSummaryValues
	},
	supplyChainCO2e: {
		dataset: 'SUPPLIERS',
		metrics: ['analyse_supplierCO2e'],
		method: transformSingleValues
	},
	executivePayGap: {
		dataset: 'ANNUAL',
		metrics: ['analyse_cEOSalaryGap'],
		method: transformSingleValues
	}
};
