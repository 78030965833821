import * as Core from '@Core/index.js';
import { useAppStore } from '../app.js';
import { useLanguageStore } from '../language.js';

/**
 * @exports Client_Store_Product
 * @namespace Client_Store_Product
 */

// @todo
// getProductSection method on bottom can be removed and we can use getProduct and instead and get the data this way. Feel redundant.
// add getStatus(), like we did in store.user, which will return object with number of all products, completed, incompleted, in progress, co2e and by type, so comprehensive list of all type of products for different scenarios.

/**
 * Frontend section categories enum for ESG products
 * @memberof Client_Store_Product
 * @type {object}
 */
const ESG_CATEGORIES = {
	accounting: 'accounting',
	operations: 'operations',
	production: 'production',
	product: 'product',
	hr: 'hr',
	governance: 'governance',
	optional: 'optional'
};

/**
 * Map config for category and each data collection section for esg
 * @memberof Client_Store_Product
 * @type {object}
 */
const SECTIONS_TO_ESG_CATEGORIES = {
	manualAccountancy: ESG_CATEGORIES.accounting,
	facilities: ESG_CATEGORIES.operations,
	vehicles: ESG_CATEGORIES.operations,
	machinery: ESG_CATEGORIES.operations,
	logistics: ESG_CATEGORIES.operations,
	products: ESG_CATEGORIES.production,
	supplyChain: ESG_CATEGORIES.production,
	customers: ESG_CATEGORIES.product,
	hr: ESG_CATEGORIES.hr,
	riskCompliance: ESG_CATEGORIES.governance,
	boardComposition: ESG_CATEGORIES.governance,
	policies: ESG_CATEGORIES.governance,
	supportingInformation: ESG_CATEGORIES.optional
};

/**
 * Frontend section categories enum for Carbon products
 * @memberof Client_Store_Product
 * @type {object}
 */
const CARBON_CATEGORIES = {
	accounting: 'accounting',
	carbon: 'carbon',
	optional: 'optional'
};

/**
 * Map config for category and each data collection section for carbon data
 * @memberof Client_Store_Product
 * @type {object}
 */
const SECTIONS_TO_CARBON_CATEGORIES = {
	manualAccountancy: CARBON_CATEGORIES.accounting,
	facilities: CARBON_CATEGORIES.carbon,
	vehicles: CARBON_CATEGORIES.carbon,
	machinery: CARBON_CATEGORIES.carbon,
	logistics: CARBON_CATEGORIES.carbon,
	products: CARBON_CATEGORIES.carbon,
	supplyChain: CARBON_CATEGORIES.carbon,
	supportingInformation: CARBON_CATEGORIES.optional
};

/**
 * Map section name to api endpoint (different naming convention)
 * @memberof Client_Store_Product
 * @type {object}
 */
const SECTIONS_TO_API = {
	manualAccountancy: 'manual-accountancy',
	facilities: 'facilities',
	logistics: 'logistics',
	vehicles: 'vehicles',
	machinery: 'machinery',
	products: 'products',
	customers: 'customers',
	riskCompliance: 'risk-compliance',
	supplyChain: 'supply-chain',
	hr: 'human-resources',
	boardComposition: 'board-composition',
	policies: 'policies',
	supportingInformation: 'supporting-information'
};

// ----------------------------- PUBLIC METHODS  --------------------------------------
export const useProductStore = Core.Pinia.defineStore({
	id: 'product',
	state: () => ({
		data: {},
		details: {}
	}),

	actions: {
		/**
		 * Pull from the API all products for a given business ID or return it from store filtered if we already have any
		 * @async
		 * @param {string}[id] - Business ID for which products should be fetched. Defaults to currently selected business
		 * @memberof Client_Store_Product
		 */
		async fetchProducts(id) {
			// @todo Separate stuff that is only for ESG
			const appStore = useAppStore();
			const currentBusinessId = id || appStore.getSelectedBusiness.id;
			this.data[currentBusinessId] = this.data[currentBusinessId] || {}; // create object for resposnivness

			// get data
			const response = (await Core.Api.get(`/business/${currentBusinessId}/products`)) || {};
			let vueesgProducts = response.body.filter(({ type }) => type === 'ASSURE');
			const vueCarbonProducts = response.body.filter(({ type }) => type === 'CARBON');
			const vueco2eProducts = response.body.filter(({ type }) => type === 'VUECO2E');

			// Load mock VueESG if no VueESG products
			// @todo Temporary - this will be handled on backend side
			if (vueesgProducts.length < 1) {
				const demoData = await import('../demoData/product.json');
				const demoProduct = Core.Utils.cloneObject(demoData.default[0]);

				// Update some data dynamically
				demoProduct.businessId = currentBusinessId;
				demoProduct.progress[currentBusinessId] = demoProduct.progress.dynamicallyUpdated;
				delete demoProduct.progress.dynamicallyUpdated;

				// Inject demo data into body
				vueesgProducts = [...response.body, demoProduct];
			} else {
				delete this.data[currentBusinessId].demoData;
			}

			// For Every VueESG (ASSURE) product do some magic to cleanup data
			// @todo this probably should be removed from here and handled separately by vueesg extended product store once introduced
			this.data[currentBusinessId] = prepareFullProducts(vueesgProducts, id);

			// For Every VueCarbon (CARBON) product do some magic to cleanup data
			if (vueCarbonProducts.length > 0) {
				this.data[currentBusinessId] = {
					...this.data[currentBusinessId],
					...prepareFullProducts(vueCarbonProducts, id, 'CARBON')
				};
			}

			// add vueco2e product as it was skipped in the "preparation loop" above
			if (vueco2eProducts.length > 0) {
				this.data[currentBusinessId][vueco2eProducts[0].id] = vueco2eProducts[0];

				// @todo this should check if vueoc2e is active subscruptiin and if yes, request data pull for banks, insights etc.
			}
		},

		/**
		 * Pull from API data for particular section
		 * Assure (ESG)
		 * @async
		 * @memberof Client_Store_Product
		 * @param {string} productId ID of product you need data for
		 * @param {string} sectionName name of the section you need data for
		 */
		async fetchProductSection(productId, sectionName) {
			const businessId = useAppStore().getSelectedBusiness.id;
			const apiEndpoint = SECTIONS_TO_API[sectionName];
			let response = {};

			// get from API
			response = await Core.Api.get(
				`/business/${businessId}/product/${productId}/${apiEndpoint}`
			);

			// populate store data
			this.data[businessId][productId].sectionDetails =
				this.data[businessId][productId].sectionDetails || {};

			this.data[businessId][productId].sectionDetails[sectionName] = response.body;
		},

		/**
		 * Save data in store and push to the API
		 * Assure (ESG)
		 * @async
		 * @memberof Client_Store_Product
		 * @param {string} productId ID of product you need data for
		 * @param {string} sectionName name of the section you need data for
		 * @param {object} sectionData same data as in store and from API, the whole object
		 */
		async saveProductSection(productId, sectionName, sectionData) {
			const businessId = useAppStore().getSelectedBusiness.id;
			const apiEndpoint = SECTIONS_TO_API[sectionName];

			// save API
			await Core.Api.post(
				`/business/${businessId}/product/${productId}/${apiEndpoint}`,
				sectionData
			);

			// save store section
			this.data[businessId][productId].sectionDetails =
				this.data[businessId][productId].sectionDetails || {};
			this.data[businessId][productId].sectionDetails[sectionName] =
				Core.Utils.cloneObject(sectionData);
		},

		/**
		 * Fetch product details for specific productID
		 * @async
		 * @memberof Client_Store_Product
		 * @param {string} productId of product
		 * @param {string} [businessId] of business
		 * @param {boolean} [force=false]  fetching of business product
		 * @returns {Promise<object>} promise of business product
		 */
		async fetchProductDetails(productId, businessId, force = false) {
			if (force || typeof this.details[productId] === 'undefined') {
				const { body } = await Core.Api.get(
					`/business/${businessId}/product/${productId}/details`
				);
				this.details[productId] = body;
				this.details[productId].status = resolveStatus(this.details[productId].status);
			}
		}
	},

	getters: {
		/**
		 * Get product data by ID only (relies on currently set business ID)
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} getter function expecting {string} id
		 */
		getProductById: (state) => {
			return (id) => {
				const businessId = useAppStore().getSelectedBusiness.id;
				return state.data[businessId]?.[id];
			};
		},

		/**
		 * Get a product details by id if not already loaded
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} with business (pass productId as attribute)
		 */
		getDetails: (state) => (id) => state.details[id],

		/**
		 * Exposing the data from store, to be used somewhere else (the mapping, SECTIONS_TO_ESG_CATEGORIES already filtered by manual accountancy enabled flag)
		 * It's really not a part of store, but this makes it easier to use / expose as store getter
		 * Assure (ESG)
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} map of categories and sections
		 */
		getSectionCategories:
			(state) =>
			(productId = null) => {
				// If no product ID is provided, return all sections
				if (!productId) {
					return SECTIONS_TO_ESG_CATEGORIES;
				}

				const businessId = useAppStore().getSelectedBusiness.id;
				const languageStore = useLanguageStore();

				const businessProduct = state.data[businessId][productId];
				const productSectionProgress = businessProduct.progress[businessId];

				const sectionsToFill = Object.keys(productSectionProgress).filter((key) => {
					if (key === 'manualAccountancy') {
						return true;
					}
					return productSectionProgress[key] !== 'HOISTED';
				});

				let objectToFilter;
				if (businessProduct['type'] === 'CARBON') {
					objectToFilter = Object.entries(SECTIONS_TO_CARBON_CATEGORIES);
				} else {
					objectToFilter = Object.entries(SECTIONS_TO_ESG_CATEGORIES);
				}

				const filteredSections = objectToFilter
					.filter(([key]) => sectionsToFill.includes(key))
					.reduce((result, [key, value]) => {
						result[key] = value;
						return result;
					}, {});

				let finalSections = {};

				const productForLang = businessProduct.type === 'ASSURE' ? 'esg' : 'carbon';

				Object.keys(filteredSections).map((section) => {
					finalSections = {
						...finalSections,
						[section]: {
							type: filteredSections[section],
							title: languageStore.string.Client[`${productForLang}Products`]
								?.sections[section]?.title,
							link: languageStore.string.Client[`${productForLang}Products`]
								?.sections[section]?.link,
							desc: languageStore.string.Client[`${productForLang}Products`]
								?.sections[section]?.desc
						}
					};
				});

				return finalSections;
			},

		/**
		 * Exposing the data from store, to be used somewhere else (the mapping, ) Assure (ESG)
		 * It's really not a part of store, but this makes it easier to use / expose as store getter
		 * @memberof Client_Store_Product
		 * @returns {object} map of categories
		 */
		getEsgCategories: () => {
			return ESG_CATEGORIES;
		},

		/**
		 * Exposing the data from store, to be used somewhere else (the mapping, ) CArbon vue
		 * It's really not a part of store, but this makes it easier to use / expose as store getter
		 * @memberof Client_Store_Product
		 * @returns {object} map of categories
		 */
		getCarbonCategories: () => {
			return CARBON_CATEGORIES;
		},

		/**
		 * Return only products that have been completed
		 * @todo This should be probably Assure (ESG)
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} with products (except demo data)
		 */
		getCompletedProducts: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			const results = {};

			for (const productId in state.data[businessId]) {
				if (
					state.data[businessId][productId].status === 'COMPLETED' &&
					productId !== 'demoData'
				) {
					results[productId] = state.data[businessId][productId];
				}
			}

			// sort by product's financial year (descending)
			const sortable = [];
			for (const item in results) {
				sortable.push({ year: results[item].financialYear, id: item });
			}
			sortable.sort(function (a, b) {
				return b.year - a.year;
			});

			const sortedResults = {};
			for (const item in sortable) {
				sortedResults[sortable[item].id] = results[sortable[item].id];
			}

			return sortedResults;
		},

		/**
		 * Return all products for currently selected business
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} with products (except demo data)
		 */
		getVueesgProducts: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			const allProducts = state.data[businessId];
			const filteredProducts = {};

			// remove demoData from response
			for (const item in allProducts) {
				const currentItem = allProducts[item];
				if (currentItem.type === 'ASSURE' && item !== 'demoData') {
					filteredProducts[item] = currentItem;
				}
			}

			return filteredProducts;
		},

		/**
		 * Return all carebion products
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} with products (except demo data)
		 */
		getVueCarbonProducts: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			const allProducts = state.data[businessId];
			const filteredProducts = {};

			// remove demoData from response
			for (const item in allProducts) {
				const currentItem = allProducts[item];
				if (currentItem.type === 'CARBON') {
					filteredProducts[item] = currentItem;
				}
			}

			return filteredProducts;
		},

		/**
		 * Return all products for currently selected business
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} with products (except demo data)
		 */
		getVueco2eProduct: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			const allProducts = state.data[businessId];

			// remove demoData from response
			for (const item in allProducts) {
				const currentItem = allProducts[item];
				if (currentItem.type === 'VUECO2E') {
					return currentItem;
				}
			}

			return {};
		},

		/**
		 * Return demo data product/s Assure (ESG)
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {object} with demo product/s
		 */
		getDemoProduct: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			const allProducts = state.data[businessId];
			const filteredProducts = {};

			// remove demoData from response
			for (const item in allProducts) {
				const currentItem = allProducts[item];

				if (item === 'demoData') {
					filteredProducts[item] = currentItem;
				}
			}

			return filteredProducts;
		},

		//
		/**
		 * Return section data
		 * @todo This is Assure (ESG)
		 * @memberof Client_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} getter function expecting {productId, sectionName} id
		 */
		getProductSection: (state) => {
			return (productId, sectionName) => {
				const businessId = useAppStore().getSelectedBusiness.id;

				return state.data[businessId][productId]?.sectionDetails?.[sectionName] ?? {};
			};
		},

		/**
		 * Checks if there are any vueesg or esgplus products available
		 * @todo move to vueesg extension
		 * @returns {boolean} status
		 */
		hasVueesgProducts: function () {
			return Object.keys(this.getVueesgProducts).length ? true : false;
		},

		/**
		 * Checks if there are any vueesg or esgplus products available
		 * TODO: this needs to check for correct product
		 * @todo move to vueesg extension
		 * @returns {boolean} status
		 */
		hasVueCarbonProducts: function () {
			return Object.keys(this.getVueCarbonProducts).length ? true : false;
		},

		/**
		 * Checks if vueco2e product is available
		 * @todo this to be moved to products.vueco2e once vueesg has been also built as extension
		 * @returns {boolean} status
		 */
		hasVueco2eProduct: function () {
			return Object.keys(this.getVueco2eProduct).length ? true : false;
		}
	}
});

// ----------------------------- PRIVATE METHODS  --------------------------------------
/**
 * Transforms progress object into completion stats
 * @memberof Client_Store_Product
 * @private
 * @param {object} progressObject - single progress object of a product
 * @returns {object} stats object per category (with additional sum and subsidiary objects) that each contains total/completed and percentage
 */
function progressStatsEsg(progressObject) {
	const statsByCategory = {
		sum: { total: 0, completed: 0, percentage: 0 },
		[ESG_CATEGORIES.accounting]: { total: 0, completed: 0, percentage: 0 },
		[ESG_CATEGORIES.operations]: { total: 0, completed: 0, percentage: 0 },
		[ESG_CATEGORIES.production]: { total: 0, completed: 0, percentage: 0 },
		[ESG_CATEGORIES.hr]: { total: 0, completed: 0, percentage: 0 },
		[ESG_CATEGORIES.product]: { total: 0, completed: 0, percentage: 0 },
		[ESG_CATEGORIES.governance]: { total: 0, completed: 0, percentage: 0 }
	};

	// Count total and completed sections
	for (const section in progressObject) {
		const category = SECTIONS_TO_ESG_CATEGORIES[section];

		const status = progressObject[section];

		// Prevent rogue keys (mainly for productId/productStatus that are inside progress object)
		if (!statsByCategory[category]) {
			continue;
		}

		if (status !== 'HOISTED') {
			// Do not count hoisted sections (subsidiaries)
			statsByCategory[category].total++;
			statsByCategory.sum.total++;
		}

		// Count completed sections
		if (status === 'COMPLETED' || status === 'DECLINED') {
			statsByCategory[category].completed++;

			statsByCategory.sum.completed++;
		}
	}
	// Calculate percentages
	for (const category in statsByCategory) {
		const { total, completed } = statsByCategory[category];
		statsByCategory[category].percentage = Core.Utils.getPercentageValue(completed, total);
	}

	return statsByCategory;
}

/**
 * Transforms progress object into completion stats for Carbon product
 * @memberof Client_Store_Product
 * @private
 * @param {object} progressObject - single progress object of a product
 * @returns {object} stats object per category (with additional sum and subsidiary objects) that each contains total/completed and percentage
 */
function progressStatsCarbon(progressObject) {
	// Without optional category, no need to calculate for that
	const statsByCategory = {
		sum: { total: 0, completed: 0, percentage: 0 },
		[CARBON_CATEGORIES.accounting]: { total: 0, completed: 0, percentage: 0 },
		[CARBON_CATEGORIES.carbon]: { total: 0, completed: 0, percentage: 0 }
	};

	// Count total and completed sections
	for (const section in progressObject) {
		const category = SECTIONS_TO_CARBON_CATEGORIES[section];

		const status = progressObject[section];

		// Prevent rogue keys (mainly for productId/productStatus that are inside progress object)
		if (!statsByCategory[category]) {
			continue;
		}

		if (status !== 'HOISTED') {
			// Do not count hoisted sections (subsidiaries)
			statsByCategory[category].total++;
			statsByCategory.sum.total++;
		}

		// Count completed sections
		if (status === 'COMPLETED' || status === 'DECLINED') {
			statsByCategory[category].completed++;

			statsByCategory.sum.completed++;
		}
	}

	// Calculate percentages
	for (const category in statsByCategory) {
		const { total, completed } = statsByCategory[category];
		statsByCategory[category].percentage = Core.Utils.getPercentageValue(completed, total);
	}

	return statsByCategory;
}

/**
 * Prepare full product data by adding stats and subsidiaries
 * @param {Array} productsArray data from the store as they are stored there
 * @param {string} businessId id of the business for which we are preparing the data
 * @param {string} [type=CARBON] type of the product (ASSURE, CARBON)
 * @returns {object} finalProducts object with all the data prepared
 */
function prepareFullProducts(productsArray, businessId, type = 'ASSURE') {
	const appStore = useAppStore();
	const currentBusinessId = businessId || appStore.getSelectedBusiness.id;

	const finalProducts = {};

	productsArray
		.sort((a, b) => b.financialYear - a.financialYear)
		.forEach((product) => {
			const includedBusinessesStats = {};

			// Iterate over each included business progress object
			for (const businessId in product.progress) {
				const progressObject = product.progress[businessId];

				if (type === 'CARBON') {
					includedBusinessesStats[businessId] = progressStatsCarbon(progressObject);
				} else {
					includedBusinessesStats[businessId] = progressStatsEsg(progressObject);
				}
			}

			// Stats for current business
			const currentBusinessStats = includedBusinessesStats[product.businessId];

			// Calculate total subsidiaries stat (if applicable)
			const subsidiaries = Object.keys(includedBusinessesStats).filter(
				(businessId) => businessId !== currentBusinessId
			);

			// Add array of subsidiaries list to product for convenience
			product.subsidiaries = subsidiaries;

			// Calculate collective Subsidiaries' progress (if applicable)
			if (subsidiaries.length) {
				const subsidiariesStats = { total: 0, completed: 0, percentage: 0 };

				for (const subsidiaryId of subsidiaries) {
					const { total, completed } = includedBusinessesStats[subsidiaryId].sum;
					subsidiariesStats.total += total;
					subsidiariesStats.completed += completed;

					// Include subsidiaries into total progress of Root business
					currentBusinessStats.sum.total += total;
					currentBusinessStats.sum.completed += completed;
				}

				// Recalculate percentages
				subsidiariesStats.percentage = Core.Utils.getPercentageValue(
					subsidiariesStats.completed,
					subsidiariesStats.total
				);
				currentBusinessStats.sum.percentage = Core.Utils.getPercentageValue(
					currentBusinessStats.sum.completed,
					currentBusinessStats.sum.total
				);
				currentBusinessStats.subsidiaries = subsidiariesStats;
			}

			product.stats = includedBusinessesStats;

			const purchasedDate = new Date(product.purchaseDate);
			const lastModifiedDate = new Date(product.modifiedOn);

			// @todo remove this when backend is fixed
			if (product.status === 'NOT_STARTED' && lastModifiedDate > purchasedDate) {
				product.status = 'IN_PROGRESS';
			}

			finalProducts[product.id] = product;
		});

	return finalProducts;
}

/**
 * Returns correct status as we normalise it for each item
 * We want to use one status (DRAFT) for anything that is not been moved forward, so we don't have to check everywhere for NOT_STARTED and IN_PROGRESS
 * @private
 * @param {string} status of the product
 * @returns {string} with correct status
 */
function resolveStatus(status) {
	return status === 'NOT_STARTED' || status === 'IN_PROGRESS' ? 'DRAFT' : status;
}
