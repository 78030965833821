/**
 @module Core/Aws/Auth
 */
import Auth from '@aws-amplify/auth';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types/index.js';
import * as Event from '@Core/modules/Event/index.js';
import { Hub } from 'aws-amplify';

let loggedInPromise; // var to cache promise from LoggedIn method

// set timeout to refresh token regularly
setInterval(
	function () {
		loggedIn(true);
	},
	60 * 60 * 1000
); // 60 minutes

// this is needed to handle errors from stupid AMPLIFY (?code url parameter is picked up automatically)
// if this is the right error, show popup and clean up the url

Hub.listen('auth', (data) => {
	// when google account has been created or accounts linked
	if (data.payload?.data?.url?.indexOf('EXTERNAL_PROVIDER_LINKED') > -1) {
		setTimeout(function () {
			Event.trigger('AUTH', 'EXTERNAL_PROVIDER_LINKED');
		}, 1000);
	}

	// when non-google account exists or hasn't been linked yet (and trying to login via google)
	if (data.payload?.data?.url?.indexOf('EXTERNAL_PROVIDER_USER_EXISTS') > -1) {
		setTimeout(function () {
			Event.trigger('AUTH', 'EXTERNAL_PROVIDER_USER_EXISTS');
		}, 1000);
	}

	setTimeout(function () {
		const url = new URL(window.location);
		// below are stripe-related payment stuff that needs to be cleaned up from URL
		// I'm not 100% sure why it's here, but I'm like 99,4% sure it's the right place, at the same time, it's not ;)
		// @TODO to be investigated
		url.searchParams.delete('error_description');
		url.searchParams.delete('state');
		url.searchParams.delete('error');
		window.history.replaceState(history.state, '', url);
	}, 1000);
});

// /* ----------------------------- PUBLIC METHODS -------------------------------- */

/**
 * accessToken populated throughout login and checks. Should be set if user is logged in.
 * @type {string}
 */
export let token;

/**
 * SignUp means register, but it will fail if user has been already created
 * @async
 * @param {string} email address from signup
 * @param {string} password provided pass
 * @returns {object} Auth.Signup response object
 */
export async function signUp(email, password) {
	const emailLc = email.toLowerCase();

	const params = {
		username: emailLc,
		password: password
	};

	return await Auth.signUp(params);
}

/**
 * Login to AWS account
 * @param {string} email address
 * @param {string} password provided
 * @returns {Promise} result of signing in
 */
export function signIn(email, password) {
	const emailLc = email.toLowerCase();
	return Auth.signIn(emailLc, password);
}

/**
 * Login to AWS account using Google
 * @param {string} code dwadwa
 * @param {object} cognitoUser dwada
 * @returns {Promise} result of signing in
 */
export function confirmSignIn(code, cognitoUser) {
	return Auth.confirmSignIn(cognitoUser, code, 'SMS_MFA');
}

/**
 * Request email with new code to reset the password
 * @param {string} email address
 * @returns {Promise} starting pass reset process
 */
export function requestReset(email) {
	const emailLc = email.toLowerCase();
	return Auth.forgotPassword(emailLc, {
		requestHost: window.location.host
	});
}

/**
 * Reset password to newly provided one (relies on email stored in LS)
 * @param {string} email email for the account we about to reset
 * @param {number} code sent via email (From link)
 * @param {string} password new entered by user
 * @returns {Promise} reset password results
 */
export function resetPassword(email, code, password) {
	const emailLc = email.toLowerCase();
	return Auth.forgotPasswordSubmit(emailLc, code, password);
}

/**
 * Check if user is logged in
 * This is so complicated as currentAuthenticatedUser won't return error if user has been removed from Cognito or disabled.
 * @param {boolean} [forceNew=false] skipped cached data and force hard refresh of token, login and access settings
 * @returns {Promise} from token refresh or session
 */
export function loggedIn(forceNew = false) {
	if (loggedInPromise && !forceNew) {
		return loggedInPromise;
	}

	loggedInPromise = new Promise((resolve, reject) => {
		Auth.currentAuthenticatedUser()
			.then(async function (user) {
				const session = await Auth.currentSession();

				token = session.accessToken.jwtToken;
				const refreshToken = session.getRefreshToken();
				user.refreshSession(refreshToken, (err) => {
					if (err) {
						reject(err);
					} else {
						// success
						app.log('LOGIN', 'Token Refreshed', user);
						resolve(user);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});

	return loggedInPromise;
}

/**
 * Start social media login process
 * @async
 * @param {string} provider name of the social login provided (setting from AWS cognito)
 * @returns {Promise} from federated sign-in
 */
export function socialLogin(provider) {
	return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider[provider] });
}

/**
 * It gets the current session and returns the access token.
 * @async
 * @returns {Promise} resolve or reject with error object
 */
export function getAccessToken() {
	return new Promise((resolve) => {
		if (token) {
			resolve(token);
		} else {
			loggedIn()
				.then(() => {
					resolve(token);
				})
				.catch(() => {
					resolve();
				});
		}
	});
}

/**
 * Confirm user registration
 * @async
 * @param {string} email account's username (which is email)
 * @param {string} code temporary confirmation code
 * @returns {Promise} resolved/rejected
 */
export function confirmSignUp(email, code) {
	return Auth.confirmSignUp(email, code, {
		clientMetadata: {
			requestHost: window.location.host
		}
	});
}

/**
 * Resend new account activation email
 * @async
 * @param {string} email account's username (which is email)
 * @returns {Promise} resolved/rejected
 */
export function resendConfirmationCode(email) {
	return Auth.resendSignUp(email, {
		requestHost: window.location.host
	});
}

/**
 * Logs the user out of the application
 * It will reload the page at the end
 */
export function logout() {
	Auth.signOut().then(() => {
		app.log('LOGIN', 'Auth user signed out!');
		window.location.reload();
	});
}

/**
 * Return current authenticated cognito user object
 * @param {boolean} [bypassCache=false] OPTIONAL if true, it will skip cache and force refresh
 * @returns {object} object with user provider data
 */
export async function getUserData(bypassCache = false) {
	try {
		const user = await Auth.currentAuthenticatedUser({ bypassCache });
		return user ?? {};
	} catch (err) {
		return {};
	}
}

/**
 * set user's preferred MFA method
 * @param {string} type MFA type
 * @returns {Promise} resolved/rejected
 */
export async function setMultiFactorAuthentication(type) {
	const user = await Auth.currentAuthenticatedUser();
	return Auth.setPreferredMFA(user, type);
}

/**
 * Verify and submit preferred user attribute
 * @param {string} attr attribute to verify
 * @param {string} type type of action that we want to perform- verify or submit
 * @param {string} [code=""] OPTIONAL verification code
 * @returns {Promise} resolved/rejected
 */
export function manageCurrentUserAttribute(attr, type, code = '') {
	switch (type) {
		case 'verify':
			return Auth.verifyCurrentUserAttribute(attr);
		case 'submit':
			return Auth.verifyCurrentUserAttributeSubmit(attr, code);
		default:
			throw new Error(`Invalid action type: ${type}`);
	}
}
