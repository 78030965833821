<template>
	<div
		class="Chart Space-bottomDouble Space-topDouble"
		data-testid="chart">
		<blueprint-loader
			v-if="loading"
			class="Space-bottomDouble Space-topDouble"
			data-testid="loader" />
		<div v-else>
			<!-- Graph, pie, etc... component  -->

			<component
				:is="apexChart"
				v-if="barChartDataIsProvided"
				width="100%"
				:type="type"
				:options="mergedOptions"
				:series="processedSeries">
			</component>

			<!-- If no data or all data is 0, show no data message -->
			<div v-else>
				<h2 class="Box--title">{{ options?.title?.text }}</h2>
				<p
					v-if="!noDataText"
					class="Text-regular Color-grey600">
					{{ language.string.cChart?.noData }}
				</p>
				<p v-else>{{ noDataText }}</p>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'BlueprintChart',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} [type='Graph'] id - which is going to be used for few things, like name of exported file
			 * @namespace Core_Blueprint_Chart
			 * @property {string} [type='Graph'] id - which is going to be used for few things, like name of exported file
			 */
			id: {
				type: String,
				required: true,
				default: 'Graph'
			},
			/**
			 * property {Array} [series=[]] - of data you want to display
			 * @namespace Core_Blueprint_Chart
			 * @property {Array} [series=[]] - of data you want to display
			 */
			series: {
				type: Array,
				required: false,
				default: () => []
			},
			/**
			 * property {object} [options={}] - to configure the chart (fillow apexchart config)
			 * @namespace Core_Blueprint_Chart
			 * @property {object} [options={}] - to configure the chart (fillow apexchart config)
			 */
			options: {
				type: Object,
				required: false,
				default: () => {}
			},
			/**
			 * property {Array | string} [options=[]] - allowedSwitch predefined options to allow switching between different types of charts
			 * @namespace Core_Blueprint_Chart
			 * @property {Array | string} [options=[]] - allowedSwitch predefined options to allow switching between different types of charts
			 */
			allowedSwitch: {
				type: [Array, String],
				required: false,
				default: () => []
			},
			/**
			 * property {boolean} [loading=false] - loading state
			 * @namespace Core_Blueprint_Chart
			 * @property {boolean} [loading=false] - loading state
			 */
			loading: {
				type: Boolean,
				required: false,
				default: false
			},
			/**
			 * property {string} [type='bar'] - apex chart type
			 * @namespace Core_Blueprint_Chart
			 * @property {string} [type='bar'] - apex chart type
			 */
			type: {
				type: String,
				required: false,
				default: 'bar'
			},
			/**
			 * property {boolean} [toolbar=true] - show the toolbar?
			 * @namespace Core_Blueprint_Chart
			 * @property {boolean} [toolbar=true] - show the toolbar?
			 */
			toolbar: {
				type: Boolean,
				required: false,
				default: true
			},
			/**
			 * property {string} [noDataText=''] - text to display when there is no data
			 * @namespace Core_Blueprint_Chart
			 * @property {string} [noDataText=''] - text to display when there is no data
			 */
			noDataText: {
				type: String,
				required: false,
				default: null
			}
		},

		// ---------- SETUP ----------
		setup(props) {
			/**
			 * TODO
			 * - review and finalise defaults, which should likely not be computed value (shoudl be constant on top first).
			 * - add custom buttons to expoert as PNG and as CSV
			 * - add support for allowedSwitch option with predefined switchables types that must be provided to the component
			 * - in the future, allow for mutliple options and series to be provided (Array) and that will add switcher button to allow different data to be displayed in the same space.
			 *   should be handled by the Chart Blueprint itself, for consistency and testing across all single-view chart with different charts underneath
			 * - add tracking for export
			 */

			// define async component so we don't end up loading 500kb library unless it is needed
			const apexChart = Core.Vue.defineAsyncComponent(() => import('vue3-apexcharts'));
			const language = useLanguageStore();

			/**
			 * Processed series for the chart - replace NaN with 0 (if some of the data is available)
			 * @namespace Core_Blueprint_Chart
			 * @returns {Array} - processed series
			 */
			const processedSeries = Core.Vue.computed(() => {
				if (props.type === 'pie' || props.type === 'radialBar') {
					return props.series.map((item) => (isNaN(item) ? 0 : item));
				} else {
					return props.series.map((dataObject) => ({
						...dataObject,
						data: dataObject.data.map((value) => (isNaN(value) ? 0 : value))
					}));
				}
			});

			/**
			 * Bar chart data is provided
			 * @namespace Core_Blueprint_Chart
			 * @returns {boolean} - if the bar chart data is provided
			 */
			const barChartDataIsProvided = Core.Vue.computed(() => {
				let hasNonZeroData = false;

				if (props.type === 'pie' || props.type === 'radialBar') {
					hasNonZeroData = processedSeries.value.some((value) => value !== 0);
				} else {
					hasNonZeroData = processedSeries.value.some((dataObject) => {
						return dataObject.data?.some(
							(value) => value !== 0 && !isNaN(value) && value
						);
					});
				}

				return hasNonZeroData;
			});

			const mergedOptions = Core.Vue.computed(() =>
				Core.Utils.mergeDeep(
					{
						chart: {
							id: `Omnevue_${props.id}`,
							zoom: false,
							fontFamily: 'Walsheim',
							toolbar: {
								show: props.toolbar,
								offsetX: -15,
								offsetY: 5,
								tools: {
									download: `<span aria-label="icon-download" class="iconFont-download Text-h1"></span>`
								}
							}
						},
						title: {
							align: 'left',
							style: {
								fontSize: '20px'
							}
						},
						yaxis: {
							labels: {
								formatter: function (val) {
									return Core.Utils.formatNumber(val);
								}
							}
						},
						colors: [
							'var(--color-primary)',
							'var(--color-secondary)',
							'var(--color-tertiary)',
							'var(--color-quinary)',
							'var(--color-senary)',
							'var(--color-septenary)',
							'var(--color-octonary)',
							'var(--color-nonary)',
							'var(--color-denary)'
						],
						stroke: {
							curve: 'smooth'
						},
						dataLabels: {
							background: { enabled: false },
							enabled: false,
							dropShadow: { enabled: false },
							style: {
								fontSize: '14px',
								fontWeight: 'normal'
							}
						},
						plotOptions: {
							pie: {
								dataLabels: {
									offset: -20 // Move data labels closer to center
								}
							},
							radialBar: {
								dataLabels: {
									name: {
										color: 'var(--color-secondary)',
										fontSize: 'var(--text-h2)',
										fontWeight: 'normal'
									},
									value: {
										color: 'var(--color-secondary)',
										fontSize: 'var(--text-h3)',
										fontWeight: 'normal',
										offsetY: 8
									}
								},
								hollow: {
									background: 'rgba(204,162,236,.1)',
									margin: 3,
									size: '70%'
								},
								track: {
									show: false
								}
							}
						},
						legend: {
							fontSize: '14px' // === 0.875rem or --text-small (following the design)
						}
					},
					props.options
				)
			);

			return {
				apexChart,
				mergedOptions,
				barChartDataIsProvided,
				language,
				processedSeries
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('Chart') {
		background-color: var(--color-background);
		padding: var(--space-double);
		border-radius: var(--radius-huge);
		@include state('noData') {
			.Chart--wrapper {
				opacity: 0.5;
				transition: opacity 0.2s ease-out;
			}
		}
	}
</style>
